import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";

type ModalOpenOnAction = "addFolder" | "deleteFolder" | "renameFolder" | "deleteFile" | null;

interface Media {
    url: string;
    blob_id: number;
    filename: string;
    content_type: string;
    size: number;
    created_at: string;
};

interface FolderAttributes {
    folder_name: string;
    folder_type: number | null;
    created_at: string;
    section_type: string;
    account: {
        id: number;
        email: string;
    };
    folder_medias: Media[];
    total_files: number | null;
};

interface Folder {
    id: string;
    type: string;
    attributes: FolderAttributes;
};

export interface ErrorPayloadType {
    token: string;
};

interface FileItemResp {
    id: number;
    name: string;
    size: string;
    date_uploaded: string;
    last_updated: string;
    uploaded_by: string;
    email: string;
    blob_id: number;
    content_type: string;
    url: string;
};

interface Meta {
    current_page: number;
    total_pages: number;
    total_count: number;
    per_page: number;
};

interface FolderFileItem {
    fileId: number;
    file: {
        name: string; 
        size: string;
    };
    issueDate: string;
    dateSigned:string;
    status: string;
    url: string
};

export interface ValidResponseType {
    data: { data: Folder[] } | FileItemResp[];
    meta: Meta;
    message: string;
};

export interface InvalidResponseType {
    errors: Array<ErrorPayloadType> | { folder_name: string[] } | string | string[];
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    openView: "folders" | "files" | "policy";
    page: number;
    selectFolderName: string;
    folderId: number | null;
    fileId: number | null;
    folderList: Folder[];
    folCurrentPage: number;
    folTotalPages: number;
    loading: boolean;
    modalOpenOn: ModalOpenOnAction;
    folderNameInput: string;
    folderNameAddErr: string;
    modalHeadingText: string;
    snackbarOpenFor: "success" | "error" | null;
    snackbarSuccMsg: string;
    snackbarErrMsg: string;
    folderFiles: FolderFileItem[]
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PoliciesController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getFoldersApiCallId: string = "";
    uploadFileCallId: string = "";
    addingFolderCallId: string = "";
    renameFolderApiCallId: string = "";
    deleteFoldApiCallId: string = "";
    getFolderFilesApiCallId: string = "";
    deleteFolderFileApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            openView: "folders",
            page: 1,
            selectFolderName: "",
            folderId: null,
            folderList: [],
            folCurrentPage: 1,
            folTotalPages: 1,
            loading: true,
            modalOpenOn: null,
            folderNameInput: "",
            folderNameAddErr: "",
            modalHeadingText: "",
            fileId: null,
            snackbarOpenFor: null,
            snackbarSuccMsg: "",
            snackbarErrMsg: "",
            folderFiles: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.status === 500) {
              return;
            }
            if (this.isValidApiResponse(responseJson)) {
              this.apiSuccCallBack(apiRequestCallId, responseJson);
            } else if (this.isInValidApiResponse(responseJson)) {
              this.apiFailCallBack(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    
    async componentDidMount() {
        const getAuthToken = await getStorageData("authToken");
        if(!getAuthToken) {
          this.handleRouting("Home");
        }
        this.getFolders(1);
    };

    apiCallFunc = async (valueData: {
        contentType?: string,
        apiEndPoint?: string,
        body?: {},
        type?: string,
        method?: string
      }) => {

        const { contentType, method, apiEndPoint, body } = valueData;
        const authToken = await getStorageData("authToken");
        const header = {
            "Content-Type": contentType,
            "token": authToken
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          apiEndPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        body &&
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    fileUploadApi = async(event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.files){
            const filesArr = event.target.files;
            const authToken = await getStorageData("authToken");
            const header = {
                "token": authToken
            };
            const formData = new FormData();
            Array.from(filesArr).forEach((files) => {
                formData.append('folder_media[]', files);
            });
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.PUTApiMethodType
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.folderEndPoints}/${this.state.folderId}/policies/upload_files`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formData
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
            this.uploadFileCallId = requestMessage.messageId;
        }
    };

    isValidApiResponse = (responseJson: ValidResponseType) => {
        return responseJson && (responseJson.meta || responseJson.data || responseJson.message);
    };
    
    isInValidApiResponse = (responseJson: InvalidResponseType) => {
        return responseJson && responseJson.errors;
    };

    apiSuccCallBack = async (apiRequestCallId: string, responseJson: ValidResponseType) => {
        if (apiRequestCallId === this.getFoldersApiCallId){
            this.handleGetFolderSucc(responseJson);
        }
        if (apiRequestCallId === this.addingFolderCallId){
            this.setState({
                snackbarOpenFor: "success",
                modalOpenOn: null,
                modalHeadingText: "",
                folderNameInput: "",
                snackbarSuccMsg: "New Folder Added"
            });
            this.getFolders(1);
        }
        if (apiRequestCallId === this.renameFolderApiCallId){
            this.setState({
                snackbarOpenFor: "success",
                modalOpenOn: null,
                modalHeadingText: "",
                folderNameInput: "",
                snackbarSuccMsg: "Folder Name Change Successfully"
            });
            this.getFolders(this.state.folCurrentPage);
        }
        if (apiRequestCallId === this.deleteFoldApiCallId){
            this.setState({
                snackbarOpenFor: "success",
                snackbarSuccMsg: "Folder Deleted Successfully"
            });
            this.handleModalOnClose();
            this.getFolders(this.state.folCurrentPage);
        }
        if (apiRequestCallId === this.getFolderFilesApiCallId){
            this.handleGetFolderFilesApiSuccess(responseJson);
        }
        if(apiRequestCallId === this.uploadFileCallId){
            this.setState({
                snackbarOpenFor: "success",
                snackbarSuccMsg: "File Uploaded Successfully"
            });
            this.getFolderFilesApi(Number(this.state.folderId), 1);
        }
        if(apiRequestCallId === this.deleteFolderFileApiCallId){
            this.setState({
                snackbarOpenFor: "success",
                snackbarSuccMsg: "File Deleted Successfully",
                modalOpenOn: null,
                modalHeadingText: ""
            });
            this.getFolderFilesApi(Number(this.state.folderId), this.state.page);
        }
    };

    apiFailCallBack = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
        if (apiRequestCallId === this.getFoldersApiCallId) {
            this.handleGetFolderFail(responseJson);
        }
        if (apiRequestCallId === this.addingFolderCallId) {
            this.handleAddNewFolderFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.renameFolderApiCallId) {
            this.handleAddNewFolderFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.deleteFoldApiCallId) {
            this.handleDeleteFolderFailure(responseJson);
        }
        if (apiRequestCallId === this.getFolderFilesApiCallId) {
            this.handleGetFilesFailure(responseJson);
        }
        if (apiRequestCallId === this.uploadFileCallId) {
            this.handleUploadFileFail(responseJson);
        }
        if (apiRequestCallId === this.deleteFolderFileApiCallId) {
            this.handleDeleteFileFail(responseJson)
        }
    };

    addNewFolderApi = async() => {
        this.addingFolderCallId = await this.apiCallFunc({
            contentType: configJSON.validationApiContentType,
            method: configJSON.POSTApiMethodType,
            apiEndPoint: configJSON.folderEndPoints,
            body: {
                data: {
                  attributes: {
                    folder_name: `${this.state.folderNameInput}`,
                    folder_type: "null",
                    section_type: "policies"
                  }
                }
            }
        });
    };

    getFolders = async(page: number) => {
        this.getFoldersApiCallId = await this.apiCallFunc({
            contentType: configJSON.validationApiContentType,
            method: configJSON.GETApiMethodType,
            apiEndPoint: `${configJSON.folderEndPoints}?section_type=policies&per_page=8&page=${page}`
        });
    };

    putRenameFolderApi = async(folderId: number) => {
        this.renameFolderApiCallId = await this.apiCallFunc({
            contentType: configJSON.validationApiContentType,
            method: configJSON.PUTApiMethodType,
            apiEndPoint: `${configJSON.folderEndPoints}/${folderId}/policies/rename_folder`,
            body: {
                data: {
                  attributes: {
                    folder_name: `${this.state.folderNameInput}`,
                    folder_type: "document"
                  }
                }
            }
        });
    };

    deleteFolderApiCall = async(id: number) => {
        this.deleteFoldApiCallId = await this.apiCallFunc({
            contentType: configJSON.validationApiContentType,
            method: configJSON.DELETEApiMethodType,
            apiEndPoint: `${configJSON.folderEndPoints}/${id}`
        });
    };

    getFolderFilesApi = async(folderId: number, page: number) => {
        this.getFolderFilesApiCallId = await this.apiCallFunc({
            contentType: configJSON.validationApiContentType,
            method: configJSON.GETApiMethodType,
            apiEndPoint: `${configJSON.folderEndPoints}/${folderId}/files?page=${page}&per_page=10`
        });
    };

    deleteFolderFile = async(fileId: number) => {
        this.deleteFolderFileApiCallId = await this.apiCallFunc({
            contentType: configJSON.validationApiContentType,
            method: configJSON.DELETEApiMethodType,
            apiEndPoint: `${configJSON.folderEndPoints}/${this.state.folderId}/policies/delete_file`,
            body: {
                data: {
                    attributes: {
                        blob_id: fileId
                    }
                }
            }
        });
    };

    handleGetFolderSucc = (responseJson: ValidResponseType) => {
        if('data' in responseJson.data){
            if(responseJson.data.data.length === 0){
                if(responseJson.meta.current_page !== 1){
                    this.getFolders(1)
                } else {
                    this.setState({
                        folderList: responseJson.data.data,
                        folCurrentPage: responseJson.meta.current_page,
                        folTotalPages: responseJson.meta.total_pages,
                        loading: false
                    });
                }
            } else {
                this.setState({
                    folderList: responseJson.data.data,
                    folCurrentPage: responseJson.meta.current_page,
                    folTotalPages: responseJson.meta.total_pages,
                    loading: false
                });
            }
        }
    };

    handleGetFolderFilesApiSuccess = (responseJson: ValidResponseType) => {
        if (Array.isArray(responseJson.data)){
            const files = responseJson.data.map( item =>{
                return {
                    fileId: item.id,
                    file: {
                        name: item.name,
                        size: item.size
                    },
                    issueDate: item.date_uploaded,
                    dateSigned: "09 Aug 2024, 08:00 PM",
                    status: "notSigned",
                    url: item.url
                }
            });
            if(responseJson.data.length === 0 && responseJson.meta.current_page > 1) {
                this.getFolderFilesApi(Number(this.state.folderId), 1);
            } else {
                this.setState({
                    folderFiles: files,
                    loading: false,
                    page: responseJson.meta.current_page,
                    folTotalPages: responseJson.meta.total_pages
                });
            }
        }
    };

    handleGetFolderFail = async(responseJson: InvalidResponseType) => {
        if((Array.isArray(responseJson.errors) && typeof responseJson.errors[0] === 'object') && (responseJson.errors[0].token === "Invalid token" || responseJson.errors[0].token === "Token has Expired")){
            await removeStorageData("authToken");
            this.handleRouting("Home");
        }
        if (typeof responseJson.errors === 'string' ) {
            const error = responseJson.errors;
            if(error === "You are not authorized to access this page.") {
                this.setState({
                    snackbarErrMsg: "Not Authorized",
                    snackbarOpenFor: "error",
                    folderList: [],
                    loading: false
                });
            }
        }
    };

    handleAddNewFolderFailureCallBack = (responseJson: InvalidResponseType) => {
        if(Array.isArray(responseJson.errors)) {
            if(typeof responseJson.errors[0] === 'object') {
                if(responseJson.errors[0].token === "Invalid token" || responseJson.errors[0].token === "Token has Expired"){
                    this.setState({
                        snackbarOpenFor: "error",
                        snackbarErrMsg: responseJson.errors[0].token,
                        modalOpenOn: null,
                        modalHeadingText: "",
                    });
                    setTimeout(async()=> {
                        await removeStorageData("authToken");
                        this.handleRouting("Home");
                    }, 3000);
                }
            }
            if( responseJson.errors[0] === "Folder name Folder name can only contain letters, numbers and spaces" ||
                responseJson.errors[0] === "Folder name already exists in this section" ||
                responseJson.errors[0] === "Folder name Folder name must be between 2 and 50 characters"
            ) {
                const message = this.handleFolderNameErrorMsg(responseJson.errors[0])
                this.setState({
                    folderNameAddErr: message
                });
            }
        } else if (typeof responseJson.errors === 'object' ) {
            const message = this.handleFolderNameErrorMsg(responseJson.errors.folder_name[0])
            this.setState({
                folderNameAddErr: message
            });
        } else if (responseJson.errors === "You are not authorized to access this page.") {
            this.setState({
                snackbarErrMsg: "Not Authorized",
                folderNameAddErr: "",
                modalOpenOn: null,
                modalHeadingText: "",
                folderNameInput: "",
                snackbarOpenFor: "error",
            });
        } else {
            this.setState({
                snackbarOpenFor: "error",
                snackbarErrMsg: "Something went wrong"
            });
        }
    };

    handleDeleteFolderFailure = (responseJson: InvalidResponseType) => {
        if(Array.isArray(responseJson.errors)) {
            if(typeof responseJson.errors[0] === 'object') {
                if(responseJson.errors[0].token === "Invalid token" || responseJson.errors[0].token === "Token has Expired"){
                    this.setState({
                        snackbarOpenFor: "error",
                        snackbarErrMsg: responseJson.errors[0].token
                    });
                    this.handleModalOnClose();
                    setTimeout(async()=> {
                        await removeStorageData("authToken");
                        this.handleRouting("Home");
                    }, 3000);
                }
            }
        } else if(responseJson.errors === "Folder not found") {
            this.setState({
                snackbarOpenFor: "error",
                snackbarErrMsg: responseJson.errors
            });
            this.handleModalOnClose();
            this.getFolders(this.state.folCurrentPage);
        } else if (responseJson.errors === "You are not authorized to access this page.") {
            this.setState({
                snackbarErrMsg: "Not Authorized",
                modalOpenOn: null,
                modalHeadingText: "",
                snackbarOpenFor: "error",
            });
        } else {
            this.setState({
                snackbarOpenFor: "error",
                snackbarErrMsg: "something went wrong !"
            });
            this.handleModalOnClose();
        }
    };

    handleGetFilesFailure=(responseJson: InvalidResponseType) => {
        if(Array.isArray(responseJson.errors)) {
            if(typeof responseJson.errors[0] === 'object') {
                if(responseJson.errors[0].token === "Invalid token" || responseJson.errors[0].token === "Token has Expired"){
                    this.handleTokenErr(responseJson.errors[0].token)
                }
            }
        }
    };

    handleUploadFileFail = (responseJson: InvalidResponseType) => {
        if(Array.isArray(responseJson.errors)) {
            if(typeof responseJson.errors[0] === 'object') {
                if(responseJson.errors[0].token === "Invalid token" || responseJson.errors[0].token === "Token has Expired"){
                    this.handleTokenErr(responseJson.errors[0].token)
                }
            }            
        }
        if(typeof responseJson.errors === "string") {
            const error = responseJson.errors;
            if(error === "No valid files were uploaded")
            this.setState({
                snackbarOpenFor: "error",
                snackbarErrMsg: "Invalid file format"
            });
            if (/^The following files already exist in this folder:/.test(error)) {
                this.setState({
                    snackbarOpenFor: "error",
                    snackbarErrMsg: "File already exist in the folder"
                });
            }
            if (responseJson.errors === "You are not authorized to access this page.") {
                this.setState({
                    snackbarErrMsg: "Not Authorized",
                    snackbarOpenFor: "error"
                });
            }
        }
    };

    handleDeleteFileFail = (responseJson: InvalidResponseType) => {
        if(Array.isArray(responseJson.errors)) {
            if(typeof responseJson.errors[0] === 'object') {
                if(responseJson.errors[0].token === "Invalid token" || responseJson.errors[0].token === "Token has Expired"){
                    this.handleTokenErr(responseJson.errors[0].token)
                }
            }
        }
        if(typeof responseJson.errors === "string") {
            if(responseJson.errors === "File not found") {
                this.setState({
                    snackbarOpenFor: "error",
                    snackbarErrMsg: "File not found",
                    modalOpenOn: null,
                    modalHeadingText: ""
                });
            }
            if (responseJson.errors === "You are not authorized to access this page.") {
                this.setState({
                    snackbarErrMsg: "Not Authorized",
                    modalOpenOn: null,
                    modalHeadingText: "",
                    snackbarOpenFor: "error",
                });
            }
        }
    };

    handleTokenErr = (error: string) => {
        this.setState({
            snackbarOpenFor: "error",
            snackbarErrMsg: error,
            modalOpenOn: null,
            modalHeadingText: ""
        });
        setTimeout(async()=> {
            await removeStorageData("authToken");
            this.handleRouting("Home");
        }, 3000);
    };

    handleFolderNameErrorMsg = (error: string) => {
        let message = "";
        if(error === "Folder name is required"){
            message = "Folder name is required.";
        }
        if(error === "Folder name can only contain letters, numbers and spaces" || error === "Folder name Folder name can only contain letters, numbers and spaces"){
            message = "Folder name must not include special characters.";
        }
        if(error === "Folder name must be between 2 and 50 characters" || error === "Folder name Folder name must be between 2 and 50 characters"){
            message = "Folder name must be 2 to 50 characters long.";
        }
        if(error === "Folder name already exists in this section" || error === "already exists in this section"){
            message = "A folder with the same name already exists.";
        }
        return message;
    };

    handleFolderOpen = (folderName: string, folderId: number) => {
        this.setState({
            openView: "files",
            selectFolderName: folderName,
            loading: true,
            folderId: folderId,
        });
        this.getFolderFilesApi(folderId, 1);
    };

    handleFolderClose = () => {
        this.setState({
            openView: "folders",
            selectFolderName: "",
            folderFiles: [],
            loading: true,
            folderId: null
        });
        this.getFolders(this.state.folCurrentPage);        
    };

    handleFolderPageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
        this.getFolders(page)
    };

    handlePageChange = (page: number) => {
        this.getFolderFilesApi(Number(this.state.folderId), page);
    };

    handlePolicyBackClick = () => {
        this.setState({
            openView: "files"
        });
        this.getFolderFilesApi(Number(this.state.folderId), this.state.page);
    };

    handleRouting = (route: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(
          getName(MessageEnum.NavigationTargetMessage),
          route
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    };

    convertToDayAndMonth = (isoTimestamp: string): string  => {
        const date = new Date(isoTimestamp);
        const options = { 
            day: 'numeric' as const, 
            month: 'short' as const 
        };
        return date.toLocaleDateString('en-GB', options);
    };

    handleModalOpenFor = (action: ModalOpenOnAction, IdNumber: number) => {
        if(action === "addFolder"){
            this.setState({
                modalOpenOn: action,
                modalHeadingText: "Add new folder"
            });
        }
        if(action === "renameFolder"){
            this.setState({
                modalOpenOn: action,
                modalHeadingText: "Rename folder",
                folderId: IdNumber
            });
        }
        if(action === "deleteFolder"){
            this.setState({
                modalOpenOn: action,
                modalHeadingText: "Confirm to delete folder",
                folderId: IdNumber
            });
        }
        if(action === "deleteFile"){
            this.setState({
                modalOpenOn: action,
                modalHeadingText: "Confirm to delete File",
                fileId: IdNumber
            });
        }
    };

    handleModalOnClose = () => {
        this.setState({
            modalOpenOn: null,
            modalHeadingText: "",
            folderNameInput: "",
            folderNameAddErr: "",
            fileId: null
        });
    };

    handleModalSubmitButton = () => {
        const { modalOpenOn, folderId } = this.state;
        if(modalOpenOn === "addFolder") {
            this.addNewFolderApi();
        }
        if(modalOpenOn === "renameFolder") {
            this.putRenameFolderApi(Number(folderId));
        }
    };

    handleFolderMenuItemButtonClick = (clickOn: string, folderId: number, folderName: string) => {
        if(clickOn === "rename") {
            this.setState({
                folderNameInput: folderName
            })
            this.handleModalOpenFor("renameFolder", folderId);
        }
        if(clickOn === "delete") {
            this.handleModalOpenFor("deleteFolder", folderId);
        }
    };

    handleSnackbarOnClose = () => {
        this.setState({
            snackbarOpenFor: null,
            snackbarErrMsg: "",
            snackbarSuccMsg: ""
        });
    };

    handleFolderNameInputChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        this.setState({
            folderNameInput: event.target.value,
            folderNameAddErr: ""
        });
    };

    handlePolicyOpen = () => {
        this.setState({
            openView: "policy"
        });
    };

    handleFileMenuOpenFor = (clickOn: string, fileId: number) => {
        if(clickOn === "delete") {
            this.handleModalOpenFor("deleteFile", fileId);
        }
    }; 
    // Customizable Area End
}
