import React from "react";

// Customizable Area Start
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { logo, mail } from "./assets";
import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";

// Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderResetPassword = () => {
        const { emailErrorState, isSendButtonClicked } = this.state;
        return (
            <Box style={webStyles.card}>
                <Box style={webStyles.logoContainer}>
                    <img src={logo} alt="Wealth Fusion Logo" style={webStyles.logo} />
                </Box>
                <Typography style={webStyles.title}>{this.resetPasswordText}</Typography>
                <Box style={webStyles.instructionsContainer}>
                    <Typography style={webStyles.instruction}>{this.resetPwdInstrucText}</Typography>
                    <Typography style={webStyles.instruction}>{this.resetPwdSecText}</Typography>
                </Box>
                <Box style={webStyles.formContainer}>
                    <Box style={webStyles.inputContainer}>
                        <Typography variant="body2" style={webStyles.emailLabelText}>
                            {this.emailLabelText}
                        </Typography>
                        <TextField
                            data-test-id = "emailInputTestId"
                            required
                            id="outlined-required"
                            defaultValue=""
                            variant="outlined"
                            autoComplete="off"
                            placeholder={this.enterEmail}
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                            data-testid="handleEmailChange"
                            style={webStyles.textField}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: (isSendButtonClicked && emailErrorState) ? "#F87171" :"#CBD5E1"
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: (isSendButtonClicked && emailErrorState) ? "#F87171" :"#CBD5E1"
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        border: (isSendButtonClicked && emailErrorState) ? "1px solid #F87171" :"none",
                                        boxShadow: (isSendButtonClicked && emailErrorState) ? "none": '0 0 0 1px #4F46E5, 0 0 0 2px #C7D2FE'
                                    }
                                },
                                "& input:-webkit-autofill": {
                                    WebkitBoxShadow: "0 0 0px 1000px #FFFFFF inset"
                                }
                            }}
                            inputProps={{ 
                                style: webStyles.textFieldInput,
                                sx: {
                                    color: "#0F172A",
                                    paddingBottom: "13px",
                                    "&::placeholder": {
                                      color: "#94A3B8",
                                      fontSize: "16px"
                                    }
                                }
                            }}
                            InputProps={{
                                style: webStyles.textFieldProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={mail} draggable={false}/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {
                            (isSendButtonClicked && emailErrorState) &&
                            <Typography variant="body2" style={webStyles.errorEmailText}>{emailErrorState}</Typography>
                        }
                    </Box>
                </Box>
                <Box style={webStyles.centered}>
                    <Button
                        data-testid="sentMail"
                        onClick={this.handleSendMailToResetPwd}
                        style={{
                            ...webStyles.button,
                            color:this.state.email.length > 3 ? "white" : "#64748B",
                            backgroundColor:this.state.email.length > 3 ? "#6366F1" : "#F1F5F9"
                        }}
                        disabled={this.state.email.length <= 3}
                    >
                        {this.buttonSendText}
                    </Button>
                </Box>
                <Box style={webStyles.footer}>
                    <Typography variant="body2" style={webStyles.backToLogin}>
                        Back to&nbsp;&nbsp;
                    </Typography>
                    <Typography
                        variant="body2"
                        data-testid="redirectToLogin"
                        onClick={this.redirectToLogin}
                        style={webStyles.link}
                    >
                        Log in
                    </Typography>
                </Box>
            </Box>
        )
    }

    renderSentMail = () => {
        return (
            <Box style={webStyles.sentMailContainer}>
                <Box style={webStyles.logoContainer}>
                    <img src={logo} alt="Wealth Fusion Logo" style={webStyles.logo} />
                </Box>
                <Box style={webStyles.textCenter}>
                    <Typography style={webStyles.createNewPwdText}>{this.resetPasswordText}</Typography>
                </Box>
                <Box style={webStyles.textCenter}>
                    <Typography style={webStyles.createPwdText}>{this.sentMailToText + " " + this.state.email}</Typography>
                </Box>
                <Box mx={'25px'} style={webStyles.resendMailContainer}>
                    <Typography
                        data-testid="redirectToLogin"
                        onClick={this.handleSendMailToResetPwd}
                        variant="body2"
                        color="blue"
                        style={webStyles.link}
                    >
                        Resend e-mail
                    </Typography>
                    <Box style={webStyles.redirectContainer}>
                        <Typography variant="body2" color="textSecondary">
                            Back to&nbsp;
                        </Typography>
                        <Typography
                            data-testid="redirectToLogin"
                            onClick={this.redirectToLogin}
                            variant="body2"
                            color="blue"
                            style={webStyles.link}
                        >
                            Log in
                        </Typography>
                    </Box>
                </Box>
            </Box>
        )
    }



    // Customizable Area End
    render() {
        // Customizable Area Start
        const { isSentEmail } = this.state;
        // Customizable Area End



        return (
            // Customizable Area Start
            // Required for all blocks
            <>
                <Box style={webStyles.boxMainContainer}>
                    {!isSentEmail ? this.renderResetPassword() : this.renderSentMail()}
                </Box>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={this.state.errorState}
                    autoHideDuration={4000}
                    onClose={this.handleCloseError}
                >
                    <Alert
                        onClose={this.handleCloseError}
                        icon={false}
                        variant="filled"
                        severity={this.state.toastType}
                        sx={{ width: '100%', fontWeight: 400, fontSize: '18px' }}
                    // style={webStyles.errorBox}
                    >
                        {this.state.formError}
                    </Alert>
                </Snackbar>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyles: Record<string, React.CSSProperties> = {
    boxMainContainer: {
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        width: "100%",
        height: "100%",
    },
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    card: {
        height: "fit-content",
        width: "458px",
        border: "3px solid #E2E8F0",
        borderRadius: "40px",
        padding: "1.5rem 0",
    },
    logoContainer: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "24px",
        marginTop: "12px"
    },
    errorEmailText: {
        color: "#DC2626",
        fontSize: "12px",
        marginTop:"5px"
    },
    logo: {
        height: "77px",
        width: "208px",
    },
    emailLabelText: {
        color: "#475569",
        fontSize: "14px",
        fontWeight: 600,
        marginBottom: "3px"
    },
    backToLogin: {
        color: "#64748B",
    },
    title: {
        fontWeight: 500,
        fontSize: "22px",
        textAlign: "center",
        color: "#0F172A",
        marginBottom: "7px",
        fontFamily: "'Inter Tight', sans-serif",
    },
    instructionsContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    instruction: {
        fontWeight: 400,
        fontSize: "13px",
        color: "#64748B",
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        margin: "20px 25px",
    },
    errorBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        color: "red",
        backgroundColor: "#fdeded",
        borderRadius: "5px",
        borderLeft: "4px solid red",
    },
    errorIcon: {
        cursor: "pointer",
    },
    inputContainer: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "20px",
    },
    textField: {
        width: "100%",
        color: "#0F172A",
        height: "40px",
    },
    textFieldProps: {
        borderRadius: "50px",
        height: "40px",
    },
    centered: {
        display: "flex",
        justifyContent: "center",
    },
    button: {
        width: "90%",
        borderRadius: "30px",
        fontSize: "16px",
        fontWeight: "600",
        textTransform: "capitalize",
        cursor: "pointer",
        height: "40px",
    },
    inactiveButton: {
        color: "#64748B",
        backgroundColor: "#E2E8F0",
    },
    footer: {
        display: "flex",
        justifyContent: "center",
        marginTop: "24px",
    },
    link: {
        color: "#6366F1",
        cursor: "pointer",
        fontWeight: 600,
    },
    iconButton: {
        cursor: "pointer",
    },
    sentMailContainer: {
        height: "fit-content",
        width: "458px",
        padding: "1rem",
        border: "3px solid #E2E8F0",
        borderRadius: "40px",
    },
    textCenter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    createNewPwdText: {
        fontWeight: 600,
        fontSize: "24px",
        marginTop: "5px",
        marginBottom: "8px"
    },
    createPwdText: {
        fontWeight: 400,
        fontSize: "16px",
        color: "#64748B",
        textAlign: "center" as const,
    },
    resendMailContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "24px",
        alignItems: "center",
        marginBottom: "10px"
    },
    loginLink: {
        cursor: "pointer",
        color: "blue",
    },
    createContainer: {
        height: "fit-content",
        width: "458px",
        padding: "1rem",
        border: "3px solid #E2E8F0",
        borderRadius: "40px",
    },
    textCenterColumn: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
    },
    resetPasswordText: {
        fontWeight: 400,
        fontSize: "18px",
    },
    resetButton: {
        width: "90%",
        borderRadius: "30px",
        fontSize: "16px",
        fontWeight: 600,
        textTransform: "capitalize" as const,
        cursor: "pointer",
    },
    redirectContainer: {
        display: "flex",
        justifyContent: "center"
    },
    successContainer: {
        height: "fit-content",
        width: "450px",
        padding: "1rem",
        border: "3px solid #E2E8F0",
        borderRadius: "40px",
    },
    successImage: {
        height: "120px",
        width: "150px",
    },
    textCenterPadding: {
        padding: "0.5rem",
        display: "flex",
        justifyContent: "center",
    },
    textCenterColumnPadding: {
        padding: "0.5rem",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
    },
    pwdChangedSuccessfully: {
        fontWeight: 400,
        fontSize: "18px",
    },
    successPwdText: {
        fontWeight: 400,
        fontSize: "13px",
        color: "#64748B",
    },
    successButton: {
        width: "90%",
        borderRadius: "30px",
        fontSize: "16px",
        fontWeight: 600,
        textTransform: "capitalize" as const,
        cursor: "pointer",
        color: "white",
        backgroundColor: "blue",
    },
    logoImage: {
        height: "77px",
        width: "208px",
    },
    errorContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "0.5rem 0",
        padding: "10px 15px",
        color: "red",
        backgroundColor: "#FEE2E2",
        borderRadius: "5px",
        borderLeft: "4px solid red",
    }
};


// Customizable Area End