import React, { Component, createRef } from "react";
import {
    Box,
    IconButton,
    Typography,
    styled,
    Menu
} from "@mui/material";
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

interface Props {
    id: number;
    folderName: string;
    fileCount: string;
    iconColor: string;
    owner?: string;
    created?: string;
    showOwner: boolean;
    handleMenuClick: (clickOn: string, folderId: number, folderName: string)=>void;
};

interface S {
    isEllipsis: boolean;
    anchorEl: HTMLElement | null;
    selectedFolderId: number | null;
};

export default class FolderView extends Component<Props, S> {

    textRef = createRef<HTMLDivElement>();

    constructor(props: Props){
        super(props);
        this.state = {
            isEllipsis: false,
            anchorEl: null,
            selectedFolderId: null
        };
    };

    static defaultProps: Partial<Props> = {
        showOwner: false,
        id: 0,
        handleMenuClick: ()=> {}
    };

    componentDidMount() {
        this.checkOverflow();
        window.addEventListener('resize', this.checkOverflow);
    };
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.checkOverflow);
    };

    checkOverflow = () => {
        if (this.textRef.current) {
          const { scrollHeight, clientHeight } = this.textRef.current;
          this.setState({ isEllipsis: scrollHeight > clientHeight });
        }
    };

    moreIconButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        this.setState({ 
            anchorEl: event.currentTarget
        });
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null
        });
    };

    onMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    handleMenuBottonClick = (action: string) => {
        this.props.handleMenuClick(action, this.props.id, this.props.folderName);
        this.setState({
            anchorEl: null
        });
    };

    renderMenu = () => {
        const { anchorEl } = this.state;
        return (
            <Menu
                open={Boolean(anchorEl)}
                id="basic-menu"
                data-test-id="publishMenuTestID"
                anchorEl={anchorEl}
                disableScrollLock={true}
                onClick={this.onMenuClick}
                onClose={this.handleMenuClose}
                elevation={0}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal:'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                slotProps={{
                    paper: {
                        style: {
                            overflow: 'visible',
                            boxShadow: '0px 0px 8px 4px #00000008',
                            borderRadius: "16px",
                            border: "1px solid #CBD5E1"
                        }
                    }
                }}
            >
                <Box style={webStyle.menuBox}>
                    <MenuItemBox
                        data-test-id = "menuRenameButtonTestId"
                        onClick={() => this.handleMenuBottonClick("rename")}
                    >
                        <EditOutlinedIcon/>
                        <Typography>
                            Rename
                        </Typography>
                    </MenuItemBox>
                    <MenuItemBox
                        data-test-id = "menuDeleteButtonTestId"
                        onClick={() => this.handleMenuBottonClick("delete")}
                    >
                        <DeleteOutlineRoundedIcon style={{color: "#DC2626"}}/>
                        <Typography style={{color: "#DC2626"}}>
                            Delete
                        </Typography>
                </MenuItemBox>
                </Box>
            </Menu>
        )
    };

    render() {
        const { folderName, fileCount, iconColor, showOwner, owner, created } = this.props;
        return (
            <MainBox style={webStyle.mainBox}>
                <Box style={webStyle.iconBox}>
                    <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.3337 7.33333L14.8463 4.35856C14.4182 3.5024 14.2041 3.07431 13.8848 2.76155C13.6024 2.48497 13.2621 2.27463 12.8884 2.14575C12.4659 2 11.9873 2 11.03 2H5.93366C4.44019 2 3.69345 2 3.12302 2.29065C2.62125 2.54631 2.2133 2.95426 1.95764 3.45603C1.66699 4.02646 1.66699 4.77319 1.66699 6.26667V7.33333M1.66699 7.33333H21.9337C24.1739 7.33333 25.294 7.33333 26.1496 7.76931C26.9023 8.1528 27.5142 8.76472 27.8977 9.51737C28.3337 10.373 28.3337 11.4931 28.3337 13.7333V19.6C28.3337 21.8402 28.3337 22.9603 27.8977 23.816C27.5142 24.5686 26.9023 25.1805 26.1496 25.564C25.294 26 24.1739 26 21.9337 26H8.06699C5.82678 26 4.70668 26 3.85103 25.564C3.09838 25.1805 2.48646 24.5686 2.10297 23.816C1.66699 22.9603 1.66699 21.8402 1.66699 19.6V7.33333Z" stroke={iconColor} stroke-width="3.33333" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <IconButton
                        data-test-id = "moreButtonTestId"
                        onClick={this.moreIconButtonClick}
                    >
                        <MoreHorizOutlinedIcon style={webStyle.menuIcon}/>
                    </IconButton>
                </Box>
                {
                    showOwner?
                    <>
                        <Typography
                            variant="body2"
                            component="span" 
                            style={webStyle.folNameTextPolicy}
                        >
                            {folderName}
                        </Typography>
                        <Typography variant="body2" style={webStyle.ownerText}>
                            {`Created by ${owner}, ${created}`}
                        </Typography>
                    </>
                    :
                    <Box style={webStyle.divBox}>
                        <div ref={this.textRef} style={webStyle.folderName}>
                            <Typography 
                                variant="body2"
                                component="span" 
                                style={webStyle.folNameText}
                            >
                                {folderName}
                            </Typography>
                        </div>
                    </Box>
                }
                <Typography style={webStyle.fileCount}>
                    {
                        fileCount === "0" || fileCount === "1"? 
                        `${fileCount} file`:
                        `${fileCount} files`
                    }
                </Typography>
                {this.renderMenu()}
            </MainBox>
        );
    }
};

const MainBox = styled(Box)({
    cursor: "pointer",
    "&:hover": {
        boxShadow:" 0px 4px 8px 1px #00000026"
    }
});

const MenuItemBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 600,
    color: "#0F172A",
    gap: "8px",
    width: "150px",
    padding: "12px 16px",
    borderRadius: "8px",
    border: "1px solid transparent",
    cursor: "pointer",
    "&:hover": {
        background: "#F8FAFC",
        borderColor: "#CBD5E1"
    },
    "& .MuiSvgIcon-root": {
        fontSize: "20px"
    },
    "& .MuiTypography-root": {
        fontSize: "14px",
        fontWeight: 600,
        color: "#0F172A",
        marginTop: "5px"
    }
});

const webStyle = {
    mainBox: {
        border: "1px solid #E2E8F0",
        background: "#FFFFFF",
        padding: "20px",
        borderRadius: "12px",
        display: "flex",
        gap: "10px",
        flexDirection: "column"
    } as React.CSSProperties,
    iconBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px"
    },
    menuBox: {
        margin: "5px 15px"
    },
    folderIcon: {
        fontSize: "40px"
    },
    addFolderMod: {
        fontSize: "18px",
        color: "#0D0D12",
        fontWeight: 600
    },
    menuIcon: {
        color: "#64748B"
    },
    folderName: {
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        textOverflow: 'ellipsis',
        lineHeight: '1.5rem'
    } as React.CSSProperties,
    fileCount: {
        fontSize: "12px",
        color: "#3B82F6"
    },
    ownerText: {
        color: "#64748B",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginTop: "-10px"
    },
    divBox: {
        display: "flex",
        alignItems: "center",
        height: "45px"
    },
    folNameText: {
        fontSize: "16px",
        color: "#334155",
        fontWeight: 600
    },
    folNameTextPolicy: {
        fontSize: "16px",
        color: "#334155",
        fontWeight: 600,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "240px"
    }
};