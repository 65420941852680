import React from "react";

// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    styled,
    Pagination,
    PaginationProps,
    Avatar,
    TextField,
    Button
} from "@mui/material";
import { userImage, clientImage, msgIcon, homeIcon } from "./assets";
import EditIcon from '@mui/icons-material/Edit';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import Drawer from "./Drawer.web";
// Customizable Area End

import ClientDashboardController, {
    Props,
    configJSON,
} from "./ClientDashboardController";

// Customizable Area Start
interface StyledPaginationProps extends PaginationProps {
    totalPages: number;
};

const clientInfo = [
    {label: "Date of birth", value: "11 Sep 1975", icon: <DateRangeRoundedIcon/>},
    {label: "Address", value: "8-10 Airport Service Rd, Portsmouth, Hampshire, PO3 5NU, United Kingdom", icon: homeIcon},
    {label: "Telephone", value: "023 9266 8886", icon: <PhoneRoundedIcon/>},
    {label: "Email", value: "robert.kelvin@rokprince.com", icon: <MailOutlineRoundedIcon/>}
];

const relatedAccounts = [
    "MCK LTD",
    "Trucking group LLC",
    "Stocktok Limited",
    "The arktop real estate Ltd",
    "Savage tools LLC"
];

const kycDocuments = [
    {fileName: "Kelvin holding docs.pdf", dateUploaded: "09 Aug 2023, 08:00 PM"},
    {fileName: "Kelvin holding NDA.pdf", dateUploaded: "11 Sep 2023, 09:22 AM"},
    {fileName: "Investment portfolio templ...", dateUploaded: "21 May 2024, 12:30 PM"},
    {fileName: "Finances template v1.Xls", dateUploaded: "22 Sep 2024, 10:00 AM"},
    {fileName: "Kelvin holdings finances.Xls", dateUploaded: "23 Oct 2024, 11:45 AM"}
];

// Customizable Area End

export default class ClientDashboard extends ClientDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderClientTable = () => {
        const { loading } = this.state;
        const dummyClientData = {
            clientName: "Jane Clarck",
            dob: "Dolor sit amet consectetur"
        };
        const tableData = Array(12).fill(dummyClientData);
        return(
            <TableContainerStyle>
                <Table>
                    <TableHeadStyle>
                        <TableRow>
                            {[
                                "Client name",
                                "DOB",
                                "-",
                                "Profile"
                            ].map((item, index)=> 
                                <TableCell key={index}>
                                    {item}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHeadStyle>
                    {
                        (tableData.length === 0)?
                        <TableBodyStyle>
                            <TableCell colSpan={4}>
                                <Box style={webStyles.noFileBox}>
                                    <Typography style={webStyles.noFileText}>
                                        {loading? "Loading...": "No file is available !"}
                                    </Typography>
                                </Box>
                            </TableCell>
                        </TableBodyStyle>:
                        <TableBodyStyle>
                        {tableData.map((item, index) => 
                            <TableRow key={index}>
                                <TableCell>
                                    {item.clientName}                            
                                </TableCell>
                                <TableCell style={webStyles.cellText}>
                                    {item.dob}
                                </TableCell>
                                <TableCell style={{...webStyles.cellText, width: "120px"}}>
                                    -
                                </TableCell>
                                <TableCell align="center" style={webStyles.cellViewText}>
                                    <Typography
                                        data-test-id={`viewProfileButtonTestId${index}`}
                                        style={webStyles.viewText}
                                        onClick={()=> this.handleShowProfile()}
                                    >
                                        {configJSON.viewProfile}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                        </TableBodyStyle>
                    }
                </Table>
            </TableContainerStyle>  
        );
    };

    renderClientList = () => {
        const { currentPage, totalPages } = this.state;
        return(
            <Box >
                <Grid container spacing={3}>
                    <Grid item md={9}>
                        <Box style={webStyles.wrapperBox}>
                            <Typography style={webStyles.listHeadingText}>
                                {configJSON.client}
                            </Typography>
                            {this.renderClientTable()}
                            <Box
                                style={webStyles.paginationBox}
                            >
                                <Typography style={webStyles.pageText}>
                                    Page { currentPage } of { totalPages }
                                </Typography>
                                <StyledPagination
                                    data-test-id="paginationTestId"
                                    page={currentPage}
                                    count={totalPages}
                                    variant="outlined"
                                    shape="rounded"
                                    totalPages={ totalPages }
                                    onChange={this.handlePageChange}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={3}>
                        <Box style={webStyles.wrapperBox}>
                            <Box style={webStyles.notHeadingBox}>
                                <Typography style={{...webStyles.listHeadingText, marginBottom: "0px"}}>
                                    {configJSON.clientRisk}
                                </Typography>
                                <Box style={webStyles.notCountBox}>
                                    <Typography>
                                    2
                                    </Typography>
                                </Box>
                            </Box>
                            <Box style={webStyles.notiItemsmainWrapper}>
                                <Box style={webStyles.notiItemsBorderWrapper}></Box>
                                <Box style={webStyles.notiItemsWrapper}>
                                    <Box style={webStyles.notiItemsContainer}>
                                        {[
                                            {name: "James Sollovans", contact: "+1 (351) 416-9648", profileImage: userImage, msgTxt: "Send a message"},
                                            {name: "Mc beverages", contact: "+1 (351) 416-9648", profileImage: null, msgTxt: "Send a message"}
                                        ].map((item, index)=>
                                            <Box key={index} style={webStyles.notificationItemBox}>
                                                {item.profileImage ? 
                                                    <Avatar style={webStyles.imgAvatar} src={item.profileImage}/>:
                                                    <Avatar
                                                        style={webStyles.txtAvatar}
                                                    >
                                                        MC
                                                    </Avatar>
                                                }
                                                <Box>
                                                    <Typography style={webStyles.notiName}>{item.name}</Typography>
                                                    <Typography style={webStyles.notiContact}>{item.contact}</Typography>
                                                    <Box style={{...webStyles.notificationItemBox, cursor: "pointer"}}>
                                                        <img src={msgIcon} alt="msg_icon"/>
                                                        <Typography style={webStyles.sendAMsgText}>
                                                            {item.msgTxt}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
    };

    renderFullAccessClientProfile = () => {
        return (
            <>
                <Box style={webStyles.clientEditHeadingBox}>
                    <Box style={webStyles.clientNameImgBox}>
                        <Avatar src={clientImage} style={{width: 48, height: 48}}/>
                        <Box>
                            <Typography style={webStyles.headingTextStyle}>Robert Kelvin Prince</Typography>
                            <Typography style={webStyles.healpingTextStyle}>
                                Added on 
                                <span style={{color: "#4F46E5"}}> Aug 24, 2023</span>
                            </Typography>
                        </Box>
                    </Box>
                    <EditButton style={{width: "108px", height: "38px",}}>
                        <Box style={webStyles.editBox}>
                            <EditIcon/>
                            <Typography style={{fontWeight: 600}}>
                                {configJSON.edit}
                            </Typography>
                        </Box>
                    </EditButton>
                </Box>
                <Box style={webStyles.inputFieldBox}>
                    {clientInfo.map((item, index) =>
                        <Box key={index}>
                            <Typography style={webStyles.clientInputLabel}>
                                {item.label}
                            </Typography>
                            {
                                index === 1?
                                <ClientTextField
                                    multiline
                                    fullWidth
                                    value={item.value}
                                    sx={{
                                        "& .MuiOutlinedInput-root":{
                                            width: "100%",
                                            minHeight: "64px",
                                            height: "auto"
                                        }
                                    }}
                                />:
                                <ClientTextField
                                    value={item.value}
                                />
                            }
                        </Box>
                    )}
                </Box>
            </>
        );
    };

    renderViewOnlyClientProfile = () => {
        const clientInfoUpdate = [clientInfo[0], clientInfo[2], clientInfo[1], clientInfo[3]]
        return (
            <> 
                <Box style={webStyles.profileHeadingBox}>
                    <Typography style={webStyles.headingTextStyle}>{configJSON.profile}</Typography>
                    <Typography style={webStyles.healpingTextStyle}>
                        {configJSON.viewProfileSubheading}
                    </Typography>
                </Box>
                <Box style={webStyles.clientViewHeadingBox}>
                    <Box style={webStyles.clientNameImgBox}>
                        <Avatar
                            style={{...webStyles.txtAvatar, width: 48, height: 48, fontSize: "16px"}}
                        >
                            MC
                        </Avatar>
                        <Box>
                            <Typography style={webStyles.headingTextStyle}>Robert Kelvin Prince</Typography>
                            <Typography style={{color: "#475569", fontSize: "14px"}}>
                                Added on 
                                <span style={{color: "#4F46E5"}}> Aug 24, 2023</span>
                            </Typography>
                        </Box>
                    </Box>
                    <Box style={{...webStyles.buttonBox, gap: "20px"}}>
                        <SendButton>
                            <Box style={webStyles.buttonBox}>
                                <ChatBubbleOutlineRoundedIcon/>
                                <Typography style={{color: "#FFFFFF", fontWeight: 600}}>
                                    {configJSON.sendMessage}
                                </Typography>
                            </Box>
                        </SendButton>
                        <ViewButton>
                            {configJSON.viewAccounts}
                        </ViewButton>
                    </Box>
                </Box>
                <Grid container sm={8.5} spacing={3} style={{ marginTop: "0px"}}>
                    {clientInfoUpdate.map((item, index) =>
                        <Grid item sm={6} key={index}>
                            <Box style={webStyles.clientInfoItemBox}>
                                <Box style={webStyles.clientEditHeadingBox}>
                                    <Typography style={webStyles.viewInfoLabel}>
                                        {item.label =="Telephone"? "Phone number": item.label}
                                    </Typography>
                                    { index === 2? 
                                        <img src={item.icon} alt="home_icon"/>:
                                        <Box style={{color: "#6366F1"}}>{item.icon}</Box>
                                    }
                                </Box>
                                <Typography style={{color: "#475569", marginTop: "5px"}}>
                                    {item.value}
                                </Typography>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </>
        );
    };

    renderProfileTable = (heading: string, helpingText: string) => {
        return (
            <Grid item sm={6}>
                <Box style={webStyles.wrapperBox}>
                    <Box style={webStyles.clientEditHeadingBox}>
                        <Box>
                            <Typography style={webStyles.headingTextStyle}>
                                {heading}
                            </Typography>
                            <Typography style={webStyles.healpingTextStyle}>
                                {helpingText}
                            </Typography>
                        </Box>
                        <EditButton style={{width: "108px", height: "38px"}}>
                            <Box style={webStyles.editBox}>
                                <FilterListRoundedIcon/>
                                <Typography style={{fontWeight: 600}}>
                                    {configJSON.filter}
                                </Typography>
                            </Box>
                        </EditButton>
                    </Box>
                    <TableContainerStyle style={{borderColor: "#F1F5F9", marginTop: "15px"}}>
                        <Table>
                            <ProfileTableHeadStyle>
                                <TableRow>
                                    {
                                        heading === "Related Accounts"?
                                        <>
                                            <TableCell>ACCOUNT</TableCell>
                                            <TableCell>-</TableCell>
                                        </>:
                                        <>
                                            <TableCell>DOCUMENTS</TableCell>
                                            <TableCell>DATE UPLOADED</TableCell>
                                        </>
                                    }
                                    <TableCell style={{textAlign: "center"}}>ACTIONS</TableCell>
                                </TableRow>
                            </ProfileTableHeadStyle>
                            <ProfileTableBodyStyle>
                                {
                                    heading === "Related Accounts"?
                                    relatedAccounts.map((item, index)=>
                                        <TableRow key={index}>
                                            <TableCell>
                                                {item}
                                            </TableCell>
                                            <TableCell style={{width: "100px"}}>
                                                -
                                            </TableCell>
                                            <TableCell style={{width: "100px", textAlign: "center"}}>
                                                <Typography>
                                                    {configJSON.viewAccount}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ):
                                    kycDocuments.map((item, index)=>
                                        <TableRow key={index}>
                                            <TableCell>
                                                {item.fileName}
                                            </TableCell>
                                            <TableCell>
                                                {item.dateUploaded}
                                            </TableCell>
                                            <TableCell style={{textAlign: "center"}}>
                                                <Typography>
                                                    {configJSON.viewFile}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </ProfileTableBodyStyle>
                        </Table>
                    </TableContainerStyle>
                </Box>
            </Grid>
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { showProfile, profileAccess } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Drawer navigation={this.props.navigation} pageName = "ClientDashboard" userType="client">
                <Box style={webStyles.mainBox}>
                    { !showProfile?
                        this.renderClientList():
                        <Box>
                            <Box style={webStyles.wrapperBox}>
                                {profileAccess === "fullaccess"?
                                    this.renderFullAccessClientProfile():
                                    this.renderViewOnlyClientProfile()
                                }
                            </Box>
                            <Grid container spacing={3} style={{marginTop: "0px"}}>
                                {this.renderProfileTable("Related Accounts", "View and manage all the accounts of your client")}
                                {this.renderProfileTable("KYC DOCUMENTS", "View and manage the client documents")}
                            </Grid>
                        </Box>
                    }
                </Box>
            </Drawer>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const EditButton = styled(Button)({
    background: "#E0E7FF",
    borderRadius: "8px",
    padding: "0px",
    color: "#4F46E5",
    fontWeight: 600,
    textTransform: "none",
    "&:hover": {
        background: "#E0E7FF",
        color: "#4F46E5"
    },
    "&:disabled": {
        background: "#E0E7FF", 
        color: "#4F46E5",
        opacity: 0.6,
        pointerEvents: "none"
    }
});

const SendButton = styled(Button)({
    background: "#6366F1",
    borderRadius: "8px",
    padding: "8px 12px",
    color: "#FFFFFF",
    fontWeight: 600,
    textTransform: "none",
    "&:hover": {
        background: "#6366F1",
        color: "#FFFFFF"
    },
    "&:disabled": {
        background: "#6366F1", 
        color: "#FFFFFF",
        opacity: 0.6,
        pointerEvents: "none"
    }
});

const ViewButton = styled(Button)({
    background: "#FFFFFF",
    borderRadius: "8px",
    padding: "8px 12px",
    color: "#475569",
    fontWeight: 600,
    textTransform: "none",
    border: "1px solid #CBD5E1",
    "&:hover": {
        background: "#FFFFFF",
        color: "#475569"
    },
    "&:disabled": {
        background: "#FFFFFF", 
        color: "#475569",
        opacity: 0.6,
        pointerEvents: "none"
    }
});

const TableContainerStyle = styled(TableContainer)({
    border: "1px solid #E2E8F0",
    borderRadius: "12px",
    overflow: "hidden",
    background: "white",
    overflowY: "scroll"
});

const ClientTextField = styled(TextField)({
"& .MuiOutlinedInput-root": {
      height: "48px",
      borderRadius: "8px",
      width: "235px",
      "& fieldset": {
        border: `1px solid #CBD5E1`,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#CBD5E1",
        borderWidth: "1px"
      },
      "&:hover fieldset": {
        borderColor: "#CBD5E1",
      },
    },
    "& .MuiInputBase-input": {
      fontSize: "16px",
      color: "#0F172A"
    },
    "& input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0px 1000px #FFFFFF inset"
    }
});

const StyledPagination = styled(Pagination)<StyledPaginationProps>(({ totalPages }) => ({
    "& .MuiPaginationItem-root": {
        margin: "0",
        border: "1px solid #E5E5E5",
        borderRadius: "0px",
        height: "32px",
        boxShadow: "0px 4px 8px -5px #00000026",
        color: "#0F172A",
        borderRight: "1px solid #E2E8F0",
        borderLeft: "none",
        fontWeight: 600,
        fontSize: "12px",
        background: "#FFFFFF"
    },
    "& .MuiPaginationItem-root[aria-label='Go to next page']": {
        marginLeft: "10px",
        borderRadius: "8px",
        border: "1px solid #E5E5E5"
    },
    "& .MuiPaginationItem-root[aria-label='Go to previous page']": {
        marginRight: "10px",
        borderRadius: "8px",
        border: "1px solid #E5E5E5"
    },
    "& .MuiPaginationItem-root[aria-label$='page 1']": {
        borderRadius: "8px 0 0 8px",
        borderLeft: "1px solid #E5E5E5",
    },
    [`& .MuiPaginationItem-root[aria-label$='page ${totalPages}']`]: {
        borderRadius: totalPages === 1? "8px": "0 8px 8px 0",
        borderRight: "1px solid #E5E5E5",
        borderLeft: "1px solid #E5E5E5",
    },
    "& .MuiPaginationItem-root:hover": {
        background: "#E2E8F0"
    },
    "& .MuiPaginationItem-root.Mui-selected": {
        background: "#E2E8F0"
    }
}));

const TableHeadStyle = styled(TableHead)({
    "& .MuiTableRow-root": {
      backgroundColor: "#F8FAFC"
    },
    "& .MuiTableCell-root": {
      borderRight: "1px solid #E2E8F0",
      borderBottom: "1px solid #E2E8F0",
      color: "#334155",
      fontSize: "14px",
      fontWeight: 500,
      padding: "8px 16px"
    },
    "& .MuiTableCell-root:last-child": {
      borderRight: "none"
    }
});

const ProfileTableHeadStyle = styled(TableHead)({
    "& .MuiTableRow-root": {
      backgroundColor: "#FFFFFF"
    },
    "& .MuiTableCell-root": {
      borderBottom: "1px solid #F1F5F9",
      color: "#64748B",
      fontSize: "14px",
      fontWeight: 400,
      padding: "8px 16px"
    }
});

const ProfileTableBodyStyle = styled(TableBody)({
    "& .MuiTableCell-root": {
      borderBottom: "1px solid #F1F5F9",
      color: "#0F172A",
      fontSize: "14px",
      fontWeight: 400,
      padding: "12px 16px"
    },
    "& .MuiTableCell-root:last-child": {
        "& .MuiTypography-root": {
            color: "#64748B",
            fontSize: "14px",
            fontWeight: 600,
            cursor: "pointer"
        }
    }
});

  
const TableBodyStyle = styled(TableBody)({
    "& .MuiTableCell-root": {
      borderRight: "1px solid #E2E8F0",
      borderBottom: "1px solid #E2E8F0",
      color: "#0F172A",
      fontSize: "14px",
      fontWeight: 500,
      padding: "12px 16px"
    },
    "& .MuiTableCell-root:last-child": {
      borderRight: "none"
    }
});

const webStyles = {
    mainBox: {
        background: "#fafaf9",
        padding: "24px",
        height: "100%",
        minHeight: "400px"
    },
    profileHeadingBox: {
        paddingBottom: "25px",
        marginBottom: "20px",
        borderBottom: "1px solid #F1F5F9"
    },
    viewInfoLabel: {
        color: "#475569",
        fontSize: "18px",
        fontWeight: 600
    },
    clientEditHeadingBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    clientViewHeadingBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: "#FAFAF9",
        border: "1px solid #E7E5E4",
        borderRadius: "16px",
        padding: "16px"
    },
    headingTextStyle: {
        color: "#0F172A",
        fontSize: "20px",
        fontWeight: 600
    },
    clientInfoItemBox: {
        border: "1px solid #E2E8F0",
        borderRadius: "12px",
        padding: "30px 25px"
    },
    healpingTextStyle: {
        color: "#64748B",
        fontSize: "14px",
    },
    clientInputLabel: {
        color: "#475569",
        fontWeight: 600,
        fontSize: "14px",
        marginBottom: "5px"
    },
    inputFieldBox: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        borderRadius: "16px",
        border: "1px solid #F1F5F9",
        marginTop: "18px",
        padding: "16px"
    } as React.CSSProperties,
    editBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        color: "#4F46E5",
        boxSizing: "border-box",
        cursor: "pointer"
    } as React.CSSProperties,
    clientNameImgBox: {
        display: "flex",
        gap: "13px"
    },
    notiItemsmainWrapper: {
        position: 'relative',
        zIndex: 0
    } as React.CSSProperties,
    notificationItemBox: {
        display: "flex",
        gap: "10px"
    },
    notiName: {
        fontSize: "14px",
        fontWeight: 600,
        color: "#0A0A0A"
    },
    notiContact: {
        fontSize: "12px",
        fontWeight: 400,
        color: "#64748B"
    },
    sendAMsgText: {
        fontSize: "12px",
        fontWeight: 400,
        color: "#8833FF"
    },
    imgAvatar: {
        width: "24px",
        height: "24px"
    },
    txtAvatar: {
        backgroundColor: "#6366F1",
        color: '#FFFFFF',
        fontSize: "10px",
        fontWeight: 800,
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    notiItemsWrapper: {
        padding: "10px",
        height: "380px",
        overflowY: "scroll",
        zIndex: 5
    } as React.CSSProperties,
    notiItemsBorderWrapper: {
        border: "1px solid #E2E8F0",
        borderRadius: "10px",
        height: "400px",
        width: "93%",
        top: "0px",
        position: 'absolute',
        zIndex: -1
    } as React.CSSProperties,
    notiItemsContainer: {
        width: "93%",
        display: "flex",
        flexDirection: "column",
        gap: "20px"
    } as React.CSSProperties,
    wrapperBox: {
        background: "#FFFFFF",
        borderRadius: "16px",
        padding: "18px"
    },
    notCountBox: {
        borderRadius: "100px",
        background: "#F87171",
        width: "24px",
        height: "24px",
        color: "#FFFFFF",
        fontSize: "12px",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    paginationBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height: "40px",
        margin: "20px 0 8px"
    },
    notHeadingBox: {
        display: "flex",
        gap: "15px",
        alignItems: "center",
        marginBottom: "10px"
    },
    listHeadingText: {
        color: "#0F172A",
        fontSize: "20px",
        fontWeight: 600,
        marginBottom: "10px"
    },
    pageText: {
        fontSize: "14px",
        color: "#0F172A",
        fontWeight: 500
    },
    noFileText: {
        fontSize: "30px",
        color: "#94A3B8",
        fontWeight: 600
    },
    cellText: {
        fontSize: "14px",
        color: "#0F172A"
    },
    cellViewText: {
        fontWeight: 600,
        color: "#6366F1",
        width: "105px"
    },
    viewText: {
        fontWeight: 600,
        fontSize: "14px",
        cursor: "pointer"
    },
    noFileBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "400px"
    },
    buttonBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px"
    }
}

// Customizable Area End