import React from "react";

// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    Modal,
    IconButton,
    Button,
    TextField,
    styled,
    Pagination,
    PaginationProps,
    Snackbar,
    Alert
} from "@mui/material";
import Drawer from "../../dashboard/src/Drawer.web";
import InsideFolder from "../../../components/src/InsideFolder";
import FolderView from "../../../components/src/FolderView";
import PageHeader from "../../../components/src/PageHeader";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// Customizable Area End

import ProductsController, {
    Props,
    configJSON,
} from "./ProductsController";

// Customizable Area Start

interface CustomPaginationProps extends PaginationProps {
    totalPages: number;
};

const colorArray = [ "#FBBF24", "#F87171", "#34D399", "#FBBF24", "#34D399", "#FBBF24", "#FBBF24", "#3B82F6", "#FBBF24" ];

// Customizable Area End

export default class Products extends ProductsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderSuccessSnackbar = () => {
        const { snackbarOpen, succSnackbarMsge } = this.state;
        return(
            <Snackbar
                open={snackbarOpen === "success"}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={4000}
                onClose={this.handleSnackbarClose}
            >
                <Alert
                    onClose={this.handleSnackbarClose}
                    severity = "success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    { succSnackbarMsge }
                </Alert>
            </Snackbar>
        )
    };

    renderErrorSnackbar = () => {
        const { snackbarOpen, errSnackbarMsg } = this.state;
        return(
            <Snackbar
                data-test-id="snackbarTestId"
                open={snackbarOpen === "error"}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={4000}
                onClose={this.handleSnackbarClose}
            >
                <Alert
                    onClose={this.handleSnackbarClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    { errSnackbarMsg }
                </Alert>
            </Snackbar>
        )
    };

    renderModal = () => {
        const { modalOpenFor, folderNameToAdd, folderAddErr, modalHeading } = this.state;
        return(
            <Modal
                open={Boolean(modalOpenFor)}
                onClose={this.handleModalClose}
            >
                <Box
                    style={webStyle.modBoxStyle}
                >
                    <Box
                        style={webStyle.modInnerBoxStyle}
                    >
                        <Box style={webStyle.modInnerTopBox}>
                            <Typography style={webStyle.addFolderMod}>{modalHeading}</Typography>
                            <IconButton
                                data-test-id="modalCloseIconTestId"
                                onClick={()=>this.handleModalClose()}
                            >
                                <CloseRoundedIcon/>
                            </IconButton>
                        </Box>
                        {   (modalOpenFor === "addFolder" || modalOpenFor === "renameFolder") &&
                            <Box style={webStyle.modInnerMidBox}>
                                <Typography style={webStyle.InputLabelStyle}>Please enter folder name:</Typography>
                                <TextField
                                    required
                                    type="text"
                                    variant="outlined"
                                    placeholder="Enter folder name"
                                    value= { folderNameToAdd }
                                    onChange={ this.handleFolderNameChange }
                                    data-test-id = "addFolderInputTestId"
                                    autoComplete="off"
                                    style={{ width: '100%' }}
                                    inputProps={{ 
                                        autoComplete: 'new-password',
                                        sx: {
                                            "&::placeholder": {
                                                color: "#94A3B8",
                                                fontSize: "16px"
                                            }
                                        }
                                    }}
                                    InputProps={{
                                        style: { borderRadius: '50px', height: '40px' }
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: folderAddErr ? "#F87171" :"#CBD5E1"
                                            },
                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                borderColor: folderAddErr ? "#F87171" :"#CBD5E1"
                                            },
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                border: folderAddErr ? "1px solid #F87171" :"none",
                                                boxShadow: folderAddErr ? "none": '0 0 0 1px #4F46E5, 0 0 0 2px #C7D2FE'
                                            }
                                        },
                                        "& input:-webkit-autofill": {
                                            WebkitBoxShadow: "0 0 0px 1000px #FFFFFF inset"
                                        }
                                    }}
                                />
                                {
                                    folderAddErr && 
                                    <Typography style={webStyle.errorText}>
                                        {folderAddErr}
                                    </Typography>
                                }
                            </Box>
                        }
                        <Box
                            style={webStyle.modInnerLastBox}
                        >
                            <CancelButton
                                data-test-id="modalCancelButtonTestId"
                                style = {{width: "100px"}}
                                onClick={()=>this.handleModalClose()}
                            >
                                Cancel
                            </CancelButton>
                            {
                                (modalOpenFor === "addFolder" || modalOpenFor === "renameFolder")?
                                <OkButton
                                    data-test-id = "modalChangeButtonTestId"
                                    style = {{width: "135px"}}
                                    onClick = {()=> this.handleModalOkButtonClick()}
                                    disabled = {folderNameToAdd.length === 0}
                                >
                                    {modalOpenFor === "addFolder"? "Add Folder": "Change"}
                                </OkButton>:
                                <DeleteButton
                                    data-test-id="modalDeleteButtonTestId"
                                    style = {{width: "135px"}}
                                    onClick = {()=> modalOpenFor === "deleteFile"?
                                        this.deleteFolderFileApi(Number(this.state.selectedFileId)):
                                        this.deleteFolderApi(Number(this.state.selectedFolderId))
                                    }
                                >
                                    Delete
                                </DeleteButton>
                            } 
                        </Box>
                    </Box>
                </Box>
            </Modal>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            openFolder,
            inFolder,
            selectedPage,
            loading,
            folders,
            foldersCurrentPage,
            totalFoldersPages,
            openFolderFiles
        } = this.state;
        const folderData = {
            folderName: inFolder,
            folderFiles: openFolderFiles,
            currentPage: selectedPage,
            totalPages: totalFoldersPages
        };
        // Customizable Area End
        return (
            // Customizable Area Start
            <Drawer navigation={this.props.navigation} pageName = "Products">
                <Box style={webStyle.mainBoxStyle}>
                    {
                        !openFolder?
                        <>
                            <PageHeader
                                data-test-id="pageHeadingTestId"
                                heading={configJSON.Products}
                                handleAddFolder={()=>this.handleOpenModal("addFolder", 0)}
                            />
                            <Box style={webStyle.folderMainBox}>
                                <Typography style={webStyle.folderText}>
                                    {configJSON.Folders}
                                </Typography>
                                {folders.length === 0?
                                    <Box
                                        style={webStyle.noFolderBox}
                                    >
                                        <Typography
                                            style={webStyle.noFolderText}
                                        >
                                            {loading? "Loading...": "No folder is available !"}
                                        </Typography>
                                    </Box>:
                                    <>
                                        <Grid
                                            container
                                            style={webStyle.folderContainerGrid}
                                            spacing={3}
                                        >
                                            {folders.map((value, index) => 
                                                <Grid
                                                    key={index}
                                                    data-test-id={`folderGrid${index}TestId`}
                                                    item 
                                                    sm={4}
                                                    md={3}
                                                >
                                                    <Box
                                                        data-test-id={`folderBoxTestId${index}`}
                                                        onClick={() => this.handleOpenFolder(value.attributes.folder_name, Number(value.id))}
                                                    >   
                                                        <FolderView
                                                            data-test-id={`folderTestId${index}`}
                                                            id={Number(value.id)}
                                                            iconColor={colorArray[index]}
                                                            fileCount={value.attributes.total_files? `${value.attributes.total_files}`: "0"}
                                                            folderName={value.attributes.folder_name}
                                                            handleMenuClick = {this.handleFolderMenuItemClick}
                                                        />
                                                    </Box>
                                                </Grid>
                                            )}
                                        </Grid>
                                        <Box style={webStyle.paginationBox}>
                                            <Typography style={webStyle.pageText}>
                                                Page { foldersCurrentPage } of { totalFoldersPages }
                                            </Typography>
                                            <PaginationStyle
                                                data-test-id="folderPaginationTestId"
                                                page={foldersCurrentPage}
                                                count={totalFoldersPages}
                                                variant="outlined"
                                                shape="rounded"
                                                totalPages={totalFoldersPages}
                                                onChange={this.handleFolderPagination}
                                            />
                                        </Box>
                                    </>
                                }
                            </Box>
                        </>:
                        <InsideFolder
                            folderData = {folderData}
                            data-test-id="insideFolderTestId"
                            handlePageChange={(page)=> this.handleFilePagination(page)}
                            backClick={this.handleCloseFolder}
                            loading = {loading}
                            handleUploadButtonClick={this.uploadFileApi}
                            handleMenuClick={this.handleFileMenuOpen}
                        />
                    }
                </Box>
                {this.renderModal()}
                {this.renderSuccessSnackbar()}
                {this.renderErrorSnackbar()}
            </Drawer>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const OkButton = styled(Button)({
    background: "#6366F1",
    borderRadius: "100px",
    padding: "10px 16px",
    color: "#FFFFFF",
    fontWeight: 600,
    textTransform: "none",
    "&:hover": {
        background: "#6366F1",
        color: "#FFFFFF"
    },
    "&:disabled": {
        background: "#6366F1", 
        color: "#FFFFFF",
        opacity: 0.6,
        pointerEvents: "none"
    }
});

const CancelButton = styled(Button)({
    background: "#EEF2FF",
    borderRadius: "100px",
    padding: "10px 16px",
    color: "#6366F1",
    fontWeight: 600,
    textTransform: "none",
    "&:hover": {
        background: "#EEF2FF",
        color: "#6366F1"
    }
});

const DeleteButton = styled(Button)({
    background: "#DC2626",
    borderRadius: "100px",
    padding: "10px 16px",
    color: "#FFFFFF",
    fontWeight: 600,
    textTransform: "none",
    "&:hover": {
        background: "#DC2626",
        color: "#FFFFFF"
    },
    "&:disabled": {
        background: "#DC2626", 
        color: "#FFFFFF",
        opacity: 0.6,
        pointerEvents: "none"
    }
});

const PaginationStyle = styled(Pagination)<CustomPaginationProps>(({ totalPages }) => ({
    "& .MuiPaginationItem-root": {
        margin: "0",
        border: "1px solid #E5E5E5",
        borderRadius: "0px",
        height: "32px",
        boxShadow: "0px 4px 8px -5px #00000026",
        color: "#0F172A",
        borderRight: "1px solid #E2E8F0",
        borderLeft: "none",
        fontWeight: 600,
        fontSize: "12px",
        background: "#FFFFFF"
    },
    "& .MuiPaginationItem-root[aria-label='Go to next page']": {
        marginLeft: "10px",
        borderRadius: "8px",
        border: "1px solid #E5E5E5"
    },
    "& .MuiPaginationItem-root[aria-label='Go to previous page']": {
        marginRight: "10px",
        borderRadius: "8px",
        border: "1px solid #E5E5E5"
    },
    "& .MuiPaginationItem-root[aria-label$='page 1']": {
        borderRadius: "8px 0 0 8px",
        borderLeft: "1px solid #E5E5E5"
    },
    [`& .MuiPaginationItem-root[aria-label$='page ${totalPages}']`]: {
        borderRadius: totalPages === 1? "8px": "0 8px 8px 0",
        borderRight: "1px solid #E5E5E5"
    },
    "& .MuiPaginationItem-root:hover": {
        background: "#E2E8F0"
    },
    "& .MuiPaginationItem-root.Mui-selected": {
        background: "#E2E8F0"
    }
}));

const webStyle = {
    mainBoxStyle: {
        padding: "24px",
        background: "#fafaf9",
        height: "100%"
    },
    folderItemGrid: {
        flexBasis: "23.2%",
        maxWidth: "25%"
    },
    modBoxStyle: {
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    noFolderBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
        height: "300px"
    },
    paginationBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height: "40px",
        marginTop: "30px"
    },
    noFolderText: {
        fontSize: "30px",
        color: "#94A3B8",
        fontWeight: 600
    },
    modInnerBoxStyle: {
        background: "#FFFFFF",
        borderRadius: "16px",
        width: "480px"
    },
    modInnerTopBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "24px 24px 12px"
    },
    pageText: {
        fontSize: "14px",
        color: "#0F172A",
        fontWeight: 500
    },
    modInnerMidBox: {
        padding: "24px",
        borderTop: "1px solid #E2E8F0",
        borderBottom: "1px solid #E2E8F0"
    },
    modInnerLastBox: {
        padding: "24px",
        display: "flex",
        justifyContent: "flex-end",
        gap: "12px"
    },
    InputLabelStyle: {
        fontSize: "14px",
        fontWeight: 600,
        color: "#475569",
        marginBottom: "3px"
    },
    errorText: {
        color: "#DC2626",
        fontSize: "12px",
        marginTop: "5px"
    },
    addFolderMod: {
        fontSize: "18px",
        color: "#0D0D12",
        fontWeight: 600
    },
    folderContainerGrid: {
        marginTop: "20px"
    },
    folderText: {
        fontSize: "20px",
        fontWeight: 600,
        color: "#0F172A"
    },
    folderMainBox: {
        marginTop: "25px"
    }
};
// Customizable Area End
