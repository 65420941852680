Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.mainMenu = "MAIN MENU";
exports.clientUpdates = "Client updates";
exports.client = "Client";
exports.detail = "Detail";
exports.updated = "Updated ";
exports.view = "View ";
exports.noteItemHeading = "Diversification portfolio for ESMT";
exports.noteItemDetail = "Updating portfolio request after the merger between LOREM ipsum dolor sit amet consectetur.";
exports.clientRisk = "Client risk";
exports.viewProfile = "View profile";
exports.edit = "Edit";
exports.filter = "Filter";
exports.viewAccount = "View account";
exports.viewFile = "View file";
exports.profile = "Profile";
exports.viewProfileSubheading = "Here you have an overview of the client information";
exports.sendMessage = "Send message";
exports.viewAccounts = "View accounts";

// Customizable Area End