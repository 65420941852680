import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id?: string;
    children: React.ReactNode;
    // Customizable Area Start
    pageName: string;
    userType: "user" | "client";
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    hoverOn: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class DrawerController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            hoverOn: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    static defaultProps: Partial<Props> = {
        userType: "user"
    };

    async componentDidMount() {
        const getToken = await getStorageData("authToken");
        if(!getToken) {
            this.handleNavigation("Home");
        }
    };

    handleNavigation =(route: string)=>{
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), route);
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
    };

    handleOnHoverEnter = (item: string) => {
        this.setState({
            hoverOn: item
        });
    };

    handleOnHoverLeave = () => {
        this.setState({
            hoverOn: ""
        });
    };

    handleLogout = async() => {
        await removeStorageData("authToken");
        this.handleNavigation("Home");
    };
    // Customizable Area End
}