import React, { Component, createRef } from "react";
import {
  Box,
  Button,
  styled,
  Typography
} from "@mui/material";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

const bold = `
<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.1667 10.0001H12.0001C12.7736 10.0001 13.5155 10.3074 14.0625 10.8544C14.6095 11.4013 14.9168 12.1432 14.9168 12.9167C14.9168 13.6903 14.6095 14.4322 14.0625 14.9791C13.5155 15.5261 12.7736 15.8334 12.0001 15.8334H6.16675V10.0001M6.16675 4.16675H11.1667C11.9403 4.16675 12.6822 4.47404 13.2291 5.02102C13.7761 5.568 14.0834 6.30987 14.0834 7.08342C14.0834 7.85696 13.7761 8.59883 13.2291 9.14581C12.6822 9.69279 11.9403 10.0001 11.1667 10.0001H6.16675V4.16675Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const italic = `
<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 4.16675L8.66669 15.8334M9.50002 4.16675H14.5H9.50002ZM6.16669 15.8334H11.1667H6.16669Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const more = `
<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.50008 10.0001C9.50008 10.4603 9.87318 10.8334 10.3334 10.8334C10.7937 10.8334 11.1667 10.4603 11.1667 10.0001C11.1667 9.53984 10.7937 9.16675 10.3334 9.16675C9.87318 9.16675 9.50008 9.53984 9.50008 10.0001Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.66671 10.0001C3.66671 10.4603 4.0398 10.8334 4.50004 10.8334C4.96028 10.8334 5.33337 10.4603 5.33337 10.0001C5.33337 9.53984 4.96028 9.16675 4.50004 9.16675C4.0398 9.16675 3.66671 9.53984 3.66671 10.0001Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.3334 10.0001C15.3334 10.4603 15.7065 10.8334 16.1667 10.8334C16.627 10.8334 17.0001 10.4603 17.0001 10.0001C17.0001 9.53984 16.627 9.16675 16.1667 9.16675C15.7065 9.16675 15.3334 9.53984 15.3334 10.0001Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const unList = `
<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.83331 15V15.0083M8.16665 5H17.3333H8.16665ZM8.16665 10H17.3333H8.16665ZM8.16665 15H17.3333H8.16665ZM4.83331 5V5.00833V5ZM4.83331 10V10.0083V10Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const orList = `
<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.83327 4.99992H17.3333M9.83327 9.99992H17.3333M10.6666 14.9999H17.3333M3.99994 13.3332C3.99994 12.8912 4.17553 12.4673 4.48809 12.1547C4.80065 11.8422 5.22458 11.6666 5.66661 11.6666C6.10863 11.6666 6.53256 11.8422 6.84512 12.1547C7.15768 12.4673 7.33327 12.8912 7.33327 13.3332C7.33327 13.8257 6.9166 14.1666 6.49994 14.5832L3.99994 16.6666H7.33327M5.66661 8.33325V3.33325L3.99994 4.99992" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const downArrow = `
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.66663 6L8.66663 10L12.6666 6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const link = `
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.33331 11.6667C8.60488 11.9438 8.92902 12.164 9.28675 12.3143C9.64448 12.4647 10.0286 12.5421 10.4166 12.5421C10.8047 12.5421 11.1888 12.4647 11.5465 12.3143C11.9043 12.164 12.2284 11.9438 12.5 11.6667L15.8333 8.33332C16.3858 7.78079 16.6963 7.03139 16.6963 6.24999C16.6963 5.46859 16.3858 4.71919 15.8333 4.16666C15.2808 3.61412 14.5314 3.30371 13.75 3.30371C12.9686 3.30371 12.2192 3.61412 11.6666 4.16666L11.25 4.58332" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.6667 8.33343C11.3951 8.05627 11.0709 7.83608 10.7132 7.68576C10.3555 7.53544 9.97135 7.45801 9.58332 7.45801C9.19529 7.45801 8.81116 7.53544 8.45343 7.68576C8.0957 7.83608 7.77155 8.05627 7.49999 8.33343L4.16666 11.6668C3.61412 12.2193 3.30371 12.9687 3.30371 13.7501C3.30371 14.5315 3.61412 15.2809 4.16666 15.8334C4.71919 16.386 5.46859 16.6964 6.24999 16.6964C7.03139 16.6964 7.78079 16.386 8.33332 15.8334L8.74999 15.4168" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

`;

const emoji = `
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.91667 12.4999C8.18823 12.7771 8.51237 12.9973 8.87011 13.1476C9.22784 13.2979 9.61197 13.3753 10 13.3753C10.388 13.3753 10.7722 13.2979 11.1299 13.1476C11.4876 12.9973 11.8118 12.7771 12.0833 12.4999M7.5 8.33325H7.50833H7.5ZM12.5 8.33325H12.5083H12.5Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const imageIcon = `
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5 6.6665H12.5083" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.1666 3.33325H5.83331C4.4526 3.33325 3.33331 4.45254 3.33331 5.83325V14.1666C3.33331 15.5473 4.4526 16.6666 5.83331 16.6666H14.1666C15.5474 16.6666 16.6666 15.5473 16.6666 14.1666V5.83325C16.6666 4.45254 15.5474 3.33325 14.1666 3.33325Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.33331 12.5L6.66665 9.16663C7.04669 8.80092 7.4778 8.6084 7.91665 8.6084C8.35549 8.6084 8.7866 8.80092 9.16665 9.16663L13.3333 13.3333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.6666 11.6665L12.5 10.8331C12.88 10.4674 13.3111 10.2749 13.75 10.2749C14.1888 10.2749 14.6199 10.4674 15 10.8331L16.6666 12.4998" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const codeBlock = `
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.83333 6.66658L2.5 9.99992L5.83333 13.3333M14.1667 6.66658L17.5 9.99992L14.1667 13.3333M11.6667 3.33325L8.33333 16.6666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const addNote = `
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 7.5V12.5M7.5 10H12.5H7.5Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const quillIcons = Quill.import("ui/icons");
quillIcons["bold"] = bold;
quillIcons["italic"] = italic;
quillIcons["more"] = more;
quillIcons["downArrow"] = downArrow;
quillIcons["link"] = link;
quillIcons["emoji"] = emoji;
quillIcons["image"] = imageIcon;
quillIcons["code-block"] = codeBlock;
quillIcons["addNote"] = addNote;

type Props = {
  value: string;
  handleAddNote: (content: string) =>void;
  handleClose: () =>void;
};

interface S {
  content: string;
};

class NoteEditor extends Component<Props, S> {
  quillDivRef: React.RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
  constructor(props: Props) {
    super(props);
    this.state = {
      content: ""
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.replaceHeaderIcon();
    }, 0);
  };

  replaceHeaderIcon = () => {
    const editor = this.quillDivRef.current;
    if (editor) {
      const headerPickerLabel = editor.querySelector(".ql-picker-label");
      const unorderList = editor.querySelector(
        ".ql-toolbar .ql-formats .ql-list[value = 'bullet']"
      );
      const orderList = editor.querySelector(
        ".ql-toolbar .ql-formats .ql-list[value = 'ordered']"
      );
      if (headerPickerLabel) {
        headerPickerLabel.innerHTML = downArrow;
      }
      if (unorderList) {
        unorderList.innerHTML = unList;
      }
      if (orderList) {
        orderList.innerHTML = orList;
      }
    }
  };

  handleContentChange = (value: string) => {
    this.setState({
      content: value
    });
  };

  handleDeleteContent = () => {
    this.setState({
      content: ""
    });
  };

  handleClose = () => {
    this.props.handleClose();
    this.handleDeleteContent();
  };

  handleAddNote = () => {
    this.props.handleAddNote(this.state.content);
    this.handleDeleteContent();
  };

  render() {
    const { content } = this.state;
    const modules = {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "more"],
          [{ list: "bullet" }, { list: "ordered" }, "downArrow"],
          ["link", "emoji", "image", { "code-block": true }, "addNote"]
        ],
        handlers: {
          addNote: this.handleAddNote
        }
      }
    };

    return (
        <>
          <div
            ref={this.quillDivRef}
            style={{
              height: "337px",
              background: "#FFFFFF",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between"
            }}
          >
            <ReactQuill
              data-test-id="reactQuillTestId"
              value={content}
              onChange={this.handleContentChange}
              modules={modules}
              placeholder={"Title\nTake a note..."}
              className="content-editor"
            />
            <Box style={webStyle.buttonBox}>
                <DeleteButton onClick={() =>this.handleDeleteContent()}>
                  <Box style={webStyle.deleteButtonBox}>
                    <DeleteOutlineRoundedIcon/>
                    <Typography style={webStyle.deleteText}>
                      Delete
                    </Typography>
                  </Box>
                </DeleteButton>
                <CloseButton
                  data-test-id="closeButtonTestId"
                  onClick={() =>this.handleClose()}
                >
                  Close
                </CloseButton>
            </Box>
          </div>
        <style>
          {`
            .ql-editor {
              max-height: 224px;
              padding-top: 25px;
              padding-bottom: 0px;
            }
            .ql-snow .ql-tooltip {
              left: 0 !important
            }
            .ql-editor.ql-blank::before {
              content: attr(data-placeholder);
              white-space: pre-wrap;
              color: #64748B;
              pointer-events: none;
              font-style: normal;
            }
            .ql-editor.ql-blank::before {
              margin-top: -9px;
              content: "Title";
              font-size: 24px;
              font-weight: bold;
            }
            .ql-editor.ql-blank::after {
              margin-top: 15px !important;
              content: " Take a note...";
              display: block;
              color: #64748B;
              font-size: 14px;
              font-weight: normal;
            }
            .ql-toolbar{
              background-color: #F1F5F9 !important;
              display: flex;
              justify-content: space-between;
              height: 50px;
            }
            .ql-toolbar.ql-snow,
            .ql-container.ql-snow
             {
              border: none !important;
            };
            .ql-snow.ql-toolbar .ql-picker-label {
              color: #475569;
              font-size: 14px;
              font-weight: 500
            }
            .ql-picker-label svg {
              stroke: #475569;
            }
            .ql-snow.ql-toolbar .ql-picker-label.ql-active,
            .ql-snow.ql-toolbar .ql-picker-label:hover,
            .ql-snow.ql-toolbar .ql-picker-label.ql-active svg,
            .ql-snow.ql-toolbar .ql-picker-label:hover svg,
            .ql-snow.ql-toolbar .ql-picker-item:hover
            {
              color: #4F46E5;
              stroke: #4F46E5 !important
            }
            .ql-header.ql-picker.ql-expanded .ql-picker-label svg {
              stroke: #cccccc;
              transform: rotate(180deg);
              transition: transform 0.3s ease;
            }
            .content-editor .ql-editor:empty::before {
              content: "Title";  /* Take a note as the second line placeholder */
              font-size: 14px;
              color: #bbb;
              position: absolute;
              top: 40px;
              left: 10px;
              pointer-events: none;
            }
            .ql-container {
              height: 225px;
              border: none;
              background-color: #FFFFFF
            }
            .ql-toolbar .ql-formats {
              display: flex;
              align-items: center;
            }
            .ql-toolbar .ql-formats:nth-child(1),
            .ql-toolbar .ql-formats:nth-child(2),
            .ql-toolbar .ql-formats:nth-child(3) {
              margin-right: 42px;
            }
            .ql-toolbar .ql-formats:last-child {
              margin-right: 0px;
            }
            .ql-toolbar .ql-formats:nth-child(1)::after,
            .ql-toolbar .ql-formats:nth-child(2)::after,
            .ql-toolbar .ql-formats:nth-child(3)::after {
              content: '|';
              color: #D6D3D1;
              margin-left: 42px;
            }
            .ql-toolbar .ql-formats button svg {
              stroke: #475569;
            }
            .ql-toolbar .ql-formats button:hover svg,
            .ql-toolbar .ql-formats .ql-active svg
             {
              stroke: #4F46E5;
            }
          `}
        </style>
      </>
    );
  }
}

export default NoteEditor;

const DeleteButton = styled(Button)({
  background: "#ffffff",
  textTransform: "none",
  "&:hover": {
    background: "#ffffff"
  }
});

const CloseButton = styled(Button)({
  background: "#ffffff",
  textTransform: "none",
  fontWeight: 600,
  fontSize: '16px',
  color: "#6366F1",
  "&:hover": {
    background: "#ffffff"
  }
});

const webStyle = {
  buttonBox: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 20px",
    gap: "5px"
  },
  deleteButtonBox: {
    display: "flex",
    color: "#DC2626",
    alignItems: "center",
    justifyContent: "center",
    fontSize: '16px',
    fontWeight: 600,
    gap:"5px"
  },
  deleteText: {
    fontWeight: 600
  }
};