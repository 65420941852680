import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";

type ModalOpenForAction = "addFolder" | "deleteFolder" | "renameFolder" | "deleteFile" | null;

interface FolderFilesItem {
    fileId: number;
    file: {
        name: string; 
        size: string;
    };
    uploadDate: string;
    lastUpdate:string;
    uploadBy: string;
    url: string
};

interface Media {
    url: string;
    blob_id: number;
    filename: string;
    content_type: string;
    size: number;
    created_at: string;
};

interface FolderAttributes {
    folder_name: string;
    folder_type: number | null;
    created_at: string;
    section_type: string;
    account: {
        id: number;
        email: string;
    };
    folder_medias: Media[];
    total_files: number | null;
};

interface Folder {
    id: string;
    type: string;
    attributes: FolderAttributes;
};

interface FileItemResp {
    id: number;
    name: string;
    size: string;
    date_uploaded: string;
    last_updated: string;
    uploaded_by: string;
    email: string;
    blob_id: number;
    content_type: string;
    url: string;
};

interface FolderFile {
    fileId: number;
    file: {
        name: string; 
        size: string;
    };
    uploadDate: string;
    lastUpdate:string;
    uploadBy: string;
    url: string
};

interface Meta {
    current_page: number;
    total_pages: number;
    total_count: number;
    per_page: number;
};

export interface ErrorPayloadType {
    token: string;
};

export interface ValidResponseType {
    data: { data: Folder[] } | FileItemResp[];
    meta: Meta;
    message: string;
};

export interface InvalidResponseType {
    errors: Array<ErrorPayloadType> | { folder_name: string[] } | string | string[];
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    openFolder: boolean;
    inFolder: string;
    selectedPage: number;
    modalOpenFor: ModalOpenForAction;
    folderNameToAdd: string;
    folderAddErr: string;
    modalHeading: string;
    selectedFileId: number | null;
    selectedFolderId: number | null;
    loading: boolean;
    folders: Folder[];
    folderFiles: FolderFilesItem[];
    foldersCurrentPage: number;
    totalFoldersPages: number;
    snackbarOpen: "success" | "error" | null;
    succSnackbarMsge: string;
    errSnackbarMsg: string;
    openFolderFiles: FolderFile[]
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ProductsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getFoldersCallId: string = "";
    addNewFolderCallId: string = "";
    deleteFolderCallId: string = "";
    changeFolderNameCallId: string = "";
    uploadFolderFileCallId: string = "";
    getFolderFilesCallId: string = "";
    deleteFolderFileCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            openFolder: false,
            inFolder: "",
            selectedPage: 1,
            modalOpenFor: null,
            folderNameToAdd: "",
            folderAddErr: "",
            modalHeading: "",
            selectedFileId: null,
            selectedFolderId: null,
            loading: true,
            folderFiles: [],
            foldersCurrentPage: 1,
            totalFoldersPages: 1,
            folders: [],
            snackbarOpen: null,
            succSnackbarMsge: "",
            errSnackbarMsg: "",
            openFolderFiles: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
      
           let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
      
            if (responseJson.status === 500) {
              return;
            }
      
            if (this.isValidResponse(responseJson)) {
              this.apiSuccessCallBack(apiRequestCallId, responseJson);
            } else if (this.isInValidResponse(responseJson)) {
              this.apiFailureCallBack(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    
    async componentDidMount() {
        const getToken = await getStorageData("authToken");
        if(!getToken) {
          this.handleNavigationRoute("Home");
        }
        this.getFoldersApi(1);
    };

    apiCallFun = async (valueData: {
        endPoint?: string,
        method?: string,
        body?: {},
        contentType?: string,
        type?: string,
      }) => {
        const { contentType, method, endPoint, body } = valueData;
        const token = await getStorageData("authToken");
        const header = {
            "Content-Type": contentType,
            "token": token
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        body &&
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
          );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    uploadFileApi = async(event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.files){
            const files = event.target.files;

            const token = await getStorageData("authToken");

            const formData = new FormData();
            Array.from(files).forEach((file) => {
                formData.append('folder_media[]', file);
            });

            const header = {
                "token": token
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.putMethodType
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.folderEndPoints}/${this.state.selectedFolderId}/products/upload_files`
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formData
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
            this.uploadFolderFileCallId = requestMessage.messageId;
        }
    };

    apiSuccessCallBack = async (apiRequestCallId: string, responseJson: ValidResponseType) => {
        if (apiRequestCallId === this.getFoldersCallId){
            this.handleGetFolderSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.addNewFolderCallId){
            this.setState({
                snackbarOpen: "success",
                modalOpenFor: null,
                modalHeading: "",
                folderNameToAdd: "",
                succSnackbarMsge: "New Folder Added"
            });
            this.getFoldersApi(1);
        }
        if (apiRequestCallId === this.changeFolderNameCallId){
            this.setState({
                snackbarOpen: "success",
                modalOpenFor: null,
                modalHeading: "",
                folderNameToAdd: "",
                succSnackbarMsge: "Folder Name Change Successfully"
            });
            this.getFoldersApi(this.state.foldersCurrentPage);
        }
        if (apiRequestCallId === this.deleteFolderCallId){
            this.setState({
                snackbarOpen: "success",
                succSnackbarMsge: "Folder Deleted Successfully"
            });
            this.handleModalClose();
            this.getFoldersApi(this.state.foldersCurrentPage);
        }
        if(apiRequestCallId === this.uploadFolderFileCallId){
            this.setState({
                snackbarOpen: "success",
                succSnackbarMsge: "File Uploaded Successfully"
            });
            this.getFilesApi(Number(this.state.selectedFolderId), 1);
        }
        if (apiRequestCallId === this.getFolderFilesCallId){
            this.handleGetFilesApiSuccess(responseJson);
        }
        if(apiRequestCallId === this.deleteFolderFileCallId){
            this.setState({
                snackbarOpen: "success",
                succSnackbarMsge: "File Deleted Successfully",
                modalOpenFor: null,
                modalHeading: ""
            });
            this.getFilesApi(Number(this.state.selectedFolderId), this.state.selectedPage);
        }
    };

    apiFailureCallBack = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
        if (apiRequestCallId === this.getFoldersCallId) {
            this.handleGetFolderFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.addNewFolderCallId) {
            this.handleAddFolderFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.changeFolderNameCallId) {
            this.handleAddFolderFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.deleteFolderCallId) {
            this.handleDeleteFolderFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.uploadFolderFileCallId) {
            this.handleUploadFileFailure(responseJson);
        }
        if (apiRequestCallId === this.getFolderFilesCallId) {
            this.handleGetFilesFailure(responseJson);
        }
        if (apiRequestCallId === this.deleteFolderFileCallId) {
            this.handleDeleteFileFailure(responseJson)
        }
    };

    isValidResponse = (responseJson: ValidResponseType) => {
        return responseJson && (responseJson.meta || responseJson.data || responseJson.message);
    };
    
    isInValidResponse = (responseJson: InvalidResponseType) => {
        return responseJson && responseJson.errors;
    };
    
    getFoldersApi = async(page: number) => {
        this.getFoldersCallId = await this.apiCallFun({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: `${configJSON.folderEndPoints}?section_type=products&per_page=8&page=${page}`
        });
    };

    addFolderApi = async() => {
        this.addNewFolderCallId = await this.apiCallFun({
            contentType: configJSON.validationApiContentType,
            method: configJSON.exampleAPiMethod,
            endPoint: configJSON.folderEndPoints,
            body: {
                data: {
                  attributes: {
                    folder_name: `${this.state.folderNameToAdd}`,
                    folder_type: "null",
                    section_type: "products"
                  }
                }
            }
        });
    };

    deleteFolderApi = async(id: number) => {
        this.deleteFolderCallId = await this.apiCallFun({
            contentType: configJSON.validationApiContentType,
            method: configJSON.deleteMethodType,
            endPoint: `${configJSON.folderEndPoints}/${id}`
        });
    };

    changeFolderNameApi = async(folderId: number) => {
        this.changeFolderNameCallId = await this.apiCallFun({
            contentType: configJSON.validationApiContentType,
            method: configJSON.putMethodType,
            endPoint: `${configJSON.folderEndPoints}/${folderId}/products/rename_folder`,
            body: {
                data: {
                  attributes: {
                    folder_name: `${this.state.folderNameToAdd}`,
                    folder_type: "document"
                  }
                }
            }
        });
    };

    getFilesApi = async(folderId: number, page: number) => {
        this.getFolderFilesCallId = await this.apiCallFun({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: `${configJSON.folderEndPoints}/${folderId}/files?page=${page}&per_page=10`
        });
    };

    deleteFolderFileApi = async(fileId: number) => {
        this.deleteFolderFileCallId = await this.apiCallFun({
            contentType: configJSON.validationApiContentType,
            method: configJSON.deleteMethodType,
            endPoint: `${configJSON.folderEndPoints}/${this.state.selectedFolderId}/forms/delete_file`,
            body: {
                data: {
                    attributes: {
                        blob_id: fileId
                    }
                }
            }
        });
    };

    handleGetFolderSuccessCallBack = (responseJson: ValidResponseType) => {
        if('data' in responseJson.data){
            if(responseJson.data.data.length === 0){
                if(responseJson.meta.current_page !== 1){
                    this.getFoldersApi(1)
                } else {
                    this.setState({
                        folders: responseJson.data.data,
                        foldersCurrentPage: responseJson.meta.current_page,
                        totalFoldersPages: responseJson.meta.total_pages,
                        loading: false
                    });
                }
            } else {
                this.setState({
                    folders: responseJson.data.data,
                    foldersCurrentPage: responseJson.meta.current_page,
                    totalFoldersPages: responseJson.meta.total_pages,
                    loading: false
                });
            }
        }
    };

    handleGetFilesApiSuccess = (responseJson: ValidResponseType) => {
        if (Array.isArray(responseJson.data)){
            const files = responseJson.data.map( item =>{
                return {
                    fileId: item.id,
                    file: {
                        name: item.name,
                        size: item.size
                    },
                    uploadDate: item.date_uploaded,
                    lastUpdate: item.last_updated,
                    uploadBy: item.uploaded_by,
                    url: item.url
                }
            });
            if(responseJson.data.length === 0 && responseJson.meta.current_page > 1) {
                this.getFilesApi(Number(this.state.selectedFolderId), 1);
            } else {
                this.setState({
                    openFolderFiles: files,
                    loading: false,
                    selectedPage: responseJson.meta.current_page,
                    totalFoldersPages: responseJson.meta.total_pages
                });
            }
        }
    };

    handleGetFolderFailureCallBack = async(responseJson: InvalidResponseType) => {
        if((Array.isArray(responseJson.errors) && typeof responseJson.errors[0] === 'object') && (responseJson.errors[0].token === "Invalid token" || responseJson.errors[0].token === "Token has Expired")){
            await removeStorageData("authToken");
            this.handleNavigationRoute("Home");
        }
        if (typeof responseJson.errors === 'string' ) {
            const error = responseJson.errors;
            if(error === "You are not authorized to access this page.") {
                this.setState({
                    errSnackbarMsg: "Not Authorized",
                    snackbarOpen: "error",
                    folders: [],
                    loading: false
                });
            }
        }
    };

    handleAddFolderFailureCallBack = (responseJson: InvalidResponseType) => {
        if(Array.isArray(responseJson.errors)) {
            if(typeof responseJson.errors[0] === 'object') {
                if(responseJson.errors[0].token === "Invalid token" || responseJson.errors[0].token === "Token has Expired"){
                    this.setState({
                        snackbarOpen: "error",
                        errSnackbarMsg: responseJson.errors[0].token,
                        modalOpenFor: null,
                        modalHeading: "",
                    });
                    setTimeout(async()=> {
                        await removeStorageData("authToken");
                        this.handleNavigationRoute("Home");
                    }, 3000);
                }
            }
            if( responseJson.errors[0] === "Folder name Folder name can only contain letters, numbers and spaces" ||
                responseJson.errors[0] === "Folder name Folder name must be between 2 and 50 characters" || 
                responseJson.errors[0] === "Folder name already exists in this section"
            ) {
                const message = this.handleErrorMsg(responseJson.errors[0])
                this.setState({
                    folderAddErr: message
                });
            }
        } else if (typeof responseJson.errors === 'object' ) {
            const message = this.handleErrorMsg(responseJson.errors.folder_name[0])
            this.setState({
                folderAddErr: message
            });
        } else if (responseJson.errors === "You are not authorized to access this page.") {
            this.setState({
                errSnackbarMsg: "Not Authorized",
                folderAddErr: "",
                modalOpenFor: null,
                modalHeading: "",
                folderNameToAdd: "",
                snackbarOpen: "error",
            });
        } else {
            this.setState({
                snackbarOpen: "error",
                errSnackbarMsg: "Something went wrong"
            });
        }
    };

    handleDeleteFolderFailureCallBack = (responseJson: InvalidResponseType) => {
        if(Array.isArray(responseJson.errors)) {
            if(typeof responseJson.errors[0] === 'object') {
                if(responseJson.errors[0].token === "Invalid token" || responseJson.errors[0].token === "Token has Expired"){
                    this.setState({
                        snackbarOpen: "error",
                        errSnackbarMsg: responseJson.errors[0].token
                    });
                    this.handleModalClose();
                    setTimeout(async()=> {
                        await removeStorageData("authToken");
                        this.handleNavigationRoute("Home");
                    }, 3000);
                }
            }
        } else if(responseJson.errors === "Folder not found") {
            this.setState({
                snackbarOpen: "error",
                errSnackbarMsg: responseJson.errors
            });
            this.handleModalClose();
            this.getFoldersApi(this.state.foldersCurrentPage);
        } else if (responseJson.errors === "You are not authorized to access this page.") {
            this.setState({
                errSnackbarMsg: "Not Authorized",
                modalOpenFor: null,
                modalHeading: "",
                snackbarOpen: "error",
            });
        } else {
            this.setState({
                snackbarOpen: "error",
                errSnackbarMsg: "something went wrong !"
            });
            this.handleModalClose();
        }
    };

    handleUploadFileFailure = (responseJson: InvalidResponseType) => {
        if(Array.isArray(responseJson.errors)) {
            if(typeof responseJson.errors[0] === 'object') {
                if(responseJson.errors[0].token === "Invalid token" || responseJson.errors[0].token === "Token has Expired"){
                    this.handleTokenError(responseJson.errors[0].token)
                }
            }            
        }
        if(typeof responseJson.errors === "string") {
            const error = responseJson.errors;
            if(error === "No valid files were uploaded")
            this.setState({
                snackbarOpen: "error",
                errSnackbarMsg: "Invalid file format"
            });
            if (/^The following files already exist in this folder:/.test(error)) {
                this.setState({
                    snackbarOpen: "error",
                    errSnackbarMsg: "File already exist in the folder"
                });
            }
            if (responseJson.errors === "You are not authorized to access this page.") {
                this.setState({
                    errSnackbarMsg: "Not Authorized",
                    snackbarOpen: "error",
                });
            }
        }
    };

    handleGetFilesFailure=(responseJson: InvalidResponseType) => {
        if(Array.isArray(responseJson.errors)) {
            if(typeof responseJson.errors[0] === 'object') {
                if(responseJson.errors[0].token === "Invalid token" || responseJson.errors[0].token === "Token has Expired"){
                    this.handleTokenError(responseJson.errors[0].token)
                }
            }
        }
    };

    handleDeleteFileFailure = (responseJson: InvalidResponseType) => {
        if(Array.isArray(responseJson.errors)) {
            if(typeof responseJson.errors[0] === 'object') {
                if(responseJson.errors[0].token === "Invalid token" || responseJson.errors[0].token === "Token has Expired"){
                    this.handleTokenError(responseJson.errors[0].token)
                }
            }
        }
        if(typeof responseJson.errors === "string") {
            if(responseJson.errors === "File not found") {
                this.setState({
                    snackbarOpen: "error",
                    errSnackbarMsg: "File not found",
                    modalOpenFor: null,
                    modalHeading: ""
                });
            }
            if (responseJson.errors === "You are not authorized to access this page.") {
                this.setState({
                    errSnackbarMsg: "Not Authorized",
                    modalOpenFor: null,
                    modalHeading: "",
                    snackbarOpen: "error",
                });
            }
        }
    };

    handleTokenError = (error: string) => {
        this.setState({
            snackbarOpen: "error",
            errSnackbarMsg: error,
            modalOpenFor: null,
            modalHeading: ""
        });
        setTimeout(async()=> {
            await removeStorageData("authToken");
            this.handleNavigationRoute("Home");
        }, 3000);
    };

    handleErrorMsg = (error: string) => {
        let message = "";
        if(error === "Folder name is required"){
            message = "Folder name is required.";
        }
        if(error === "Folder name can only contain letters, numbers and spaces" || error === "Folder name Folder name can only contain letters, numbers and spaces"){
            message = "Folder name must not include special characters.";
        }
        if(error === "Folder name must be between 2 and 50 characters" || error === "Folder name Folder name must be between 2 and 50 characters"){
            message = "Folder name must be 2 to 50 characters long.";
        }
        if(error === "already exists in this section" || error === "Folder name already exists in this section"){
            message = "A folder with the same name already exists.";
        }
        return message;
    };

    handleFilePagination = (page: number) => {
        this.getFilesApi(Number(this.state.selectedFolderId), page);
    };

    handleFolderPagination = (_event: React.ChangeEvent<unknown>, page: number) => {
        this.getFoldersApi(page)
    };

    handleFileMenuOpen = (clickOn: string, fileId: number) => {
        if(clickOn === "delete") {
            this.handleOpenModal("deleteFile", fileId);
        }
    }; 

    handleCloseFolder = () => {
        this.getFoldersApi(this.state.foldersCurrentPage);
        this.setState({
            openFolder: false,
            inFolder: "",
            folderFiles: [],
            loading: true,
            selectedFolderId: null
        });
    };

    handleOpenFolder = (folderName: string, folderId: number) => {
        this.setState({
            loading: true,
            selectedFolderId: folderId,
            openFolder: true,
            inFolder: folderName
        });
        this.getFilesApi(folderId, 1);
    };

    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: null,
            errSnackbarMsg: "",
            succSnackbarMsge: ""
        });
    };

    handleOpenModal = (action: ModalOpenForAction, IdNumber: number) => {
        if(action === "addFolder"){
            this.setState({
                modalOpenFor: action,
                modalHeading: "Add new folder"
            });
        }
        if(action === "renameFolder"){
            this.setState({
                modalOpenFor: action,
                modalHeading: "Rename folder",
                selectedFolderId: IdNumber
            });
        }
        if(action === "deleteFolder"){
            this.setState({
                modalOpenFor: action,
                modalHeading: "Confirm to delete folder",
                selectedFolderId: IdNumber
            });
        }
        if(action === "deleteFile"){
            this.setState({
                modalOpenFor: action,
                modalHeading: "Confirm to delete File",
                selectedFileId: IdNumber
            });
        }
    };

    handleModalClose = () => {
        this.setState({
            modalOpenFor: null,
            modalHeading: "",
            folderNameToAdd: "",
            folderAddErr: "",
            selectedFileId: null
        });
    };

    handleFolderNameChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        this.setState({
            folderNameToAdd: event.target.value,
            folderAddErr: ""
        });
    };

    handleNavigationRoute = (route: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(
          getName(MessageEnum.NavigationTargetMessage),
          route
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    };

    handleModalOkButtonClick = () => {
        const { modalOpenFor, selectedFolderId } = this.state;
        if(modalOpenFor === "addFolder") {
            this.addFolderApi();
        }
        if(modalOpenFor === "renameFolder") {
            this.changeFolderNameApi(Number(selectedFolderId));
        }
    };

    handleFolderMenuItemClick = (clickOn: string, folderId: number, folderName: string) => {
        if(clickOn === "rename") {
            this.setState({
                folderNameToAdd: folderName
            })
            this.handleOpenModal("renameFolder", folderId);
        }
        if(clickOn === "delete") {
            this.handleOpenModal("deleteFolder", folderId);
        }
    };

    // Customizable Area End
}