import React from "react";

// Customizable Area Start
import { 
  Box, 
  Button, 
  Checkbox, 
  FormControlLabel, 
  IconButton, 
  InputAdornment, 
  TextField, 
  Typography 
} from '@mui/material';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController.web";
import { logo, mail, password, check, uncheck } from "./assets";

// Customizable Area End

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleRenderPassword = () => {
    return !this.state.showPassword ? 
    <VisibilityOutlinedIcon
      onClick={this.togglePasswordVisibility}
      data-testid='togglePasswordVisibility'
      style={webStyles.passEyeIcon}
    /> :
    <VisibilityOffOutlinedIcon
      style={webStyles.passEyeIcon}
      onClick={this.togglePasswordVisibility}
    />
  };

  renderTextField = (label: string, value: string, placeholder: string, testId: string, handleChange: (event: React.ChangeEvent<HTMLInputElement>)=>void, error: string) => {
    return (
      <Box style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
        <Typography style={webStyles.labelStyle}>{label}</Typography>
        <TextField
          required
          type={(label === "Email" || this.state.showPassword) ? "text" : "password"}
          id="outlined-required"
          defaultValue=""
          variant="outlined"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          data-testid={testId}
          autoComplete="off"
          style={{ width: '100%' }}
          inputProps={{ 
            style: { paddingTop: '21px' },
            autoComplete: 'new-password',
            sx: {
              "&::placeholder": {
                color: "#94A3B8",
                fontSize: "16px"
              }
            }
          }}
          InputProps={{
            style: { borderRadius: '50px', height: '40px' },
            startAdornment: (
              <InputAdornment position="start">
                <img src={label === "Email"? mail: password} />
              </InputAdornment>
            ),
            endAdornment: ( label === "Password" &&
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  aria-label="toggle password visibility"
                >
                  {this.handleRenderPassword()}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: (this.state.loginClicked && error) ? "#F87171" :"#CBD5E1"
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: (this.state.loginClicked && error) ? "#F87171" :"#CBD5E1"
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: (this.state.loginClicked && error) ? "1px solid #F87171" :"none",
                boxShadow: (this.state.loginClicked && error) ? "none": '0 0 0 1px #4F46E5, 0 0 0 2px #C7D2FE'
              }
            },
            "& input:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0px 1000px #FFFFFF inset"
            }
          }}
        />
        {
          (this.state.loginClicked && error) && 
          <Typography style={webStyles.errorText}>
            {error}
          </Typography>
        }
      </Box>
    );
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
        <Box style={{ display: "flex", width: "100%", height: "100%", justifyContent: 'center', alignItems: 'center' }}>
          <Box style={ webStyles.centerBox}>
            <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: '24px', marginTop: '30px' }}>
              <Box style={ webStyles.logoBox}>
                <img src={logo} alt="Wealth Fusion Logo" style={webStyles.logoStyle}/>
              </Box>
            </Box>
            <Box style={{ display: "flex", justifyContent: 'center' }}>
              <Typography style={{ fontWeight: '400', fontSize: '18px' }}>Let’s log in to your account</Typography>
            </Box>
            {
              this.state.errorLogin && 
              <Box style={webStyles.snackbarStyle}>
                <Typography variant="caption">
                  {this.state.errorMessage}
                </Typography>
                <IconButton 
                  style={webStyles.iconButtonSnackbar}
                  onClick={this.handleSnackbarClose}
                >
                  <CloseRoundedIcon style={webStyles.iconCloseButton}/>
                </IconButton>
              </Box>
            }
            <Box display={'flex'} flexDirection={"column"} mx={"25px"} mt={"20px"}>
              {this.renderTextField("Email", this.state.email, "johndoe@mail.com", "handleEmailChange", this.handleEmailChange, this.state.emailError)}
              {this.renderTextField("Password", this.state.password, "Enter your password", "handlePasswordChange", this.handlePasswordChange, this.state.passError)}
            </Box>


            <Box mx={'25px'} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
              <FormControlLabel
                  data-testid="formcheckbox"
                control={<Checkbox
                  icon={<img src={uncheck} data-testid="toggleCheckbox" onClick={this.toggleCheckbox} />}
                  checkedIcon={<img src={check} onClick={this.toggleCheckbox} data-testid='toggleCheckbox' />}
                  checked={this.state.isChecked}
                  onChange={this.toggleCheckbox}
                />}
                label={
                  <Typography variant="body2" color="textSecondary" style={{ marginTop: '2px', color: "#0F172A" }}>
                    Keep me logged in
                  </Typography>
                }
              />
              <Typography data-testid="redirectToForgotPassword" onClick={this.redirectToForgotPassword} variant="body2" color="#6366F1" style={{ marginTop: '10px', fontWeight: 600, cursor: 'pointer' }}>
                Forgot password?
              </Typography>
            </Box>

            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Button 
                data-testid="redirectToDashboard" 
                onClick={this.handleLogin} 
                style={{ 
                  ...webStyles.logInButton, 
                  color: this.state.email && this.state.password ? 'White' : '#64748B',
                  backgroundColor: this.state.email && this.state.password ? '#6366F1' : '#F1F5F9'
                }} 
                disabled={!(this.state.email && this.state.password)} 
              >
                Log In
              </Button>
            </Box>

          </Box>
        </Box>
      </>
      // Customizable Area End
    );
  }

}

// Customizable Area Start

const webStyles = {
  centerBox: {
    width: "445px",
    border: '3px solid #E2E8F0',
    borderRadius: '40px',
    paddingBottom: "30px"
  },
  acceptButton: {
    color: 'red'
  },
  snackbarStyle: {
    height: "48px",
    width: "394px",
    background: "#FEE2E2",
    margin: "auto",
    borderLeft: "4px solid #DC2626",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#DC2626",
    padding: "0 2px 0 13px",
    boxSizing: "border-box",
    marginTop: "12px"
  } as React.CSSProperties,
  iconButtonSnackbar: {
    color: "#DC2626"
  },
  iconCloseButton: {
    fontSize: "20px"
  },
  logoBox: { 
    width: "208px",
    height: "77px",
    overflow: "hidden",
    position: "relative"
  } as React.CSSProperties,
  logoStyle: { 
    width: '208px',
    position: "absolute",
    top: "-16px"
  } as React.CSSProperties,
  logInButton: {
    width: '394px',
    borderRadius: '30px',
    fontSize: '16px',
    fontWeight: '600',
    textTransform: 'none',
    cursor: 'pointer'
  } as React.CSSProperties,
  errorText: {
    color: "#DC2626",
    fontSize: "12px",
    marginTop: "5px"
  },
  labelStyle: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#475569",
    marginBottom: "3px"
  },
  passEyeIcon: {
    cursor: 'pointer',
    color: "#94A3B8",
    fontSize: "25px"
  }
};

// Customizable Area End