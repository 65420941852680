import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id?: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    currentPage: number;
    totalPages: number;
    showProfile: boolean;
    profileAccess: "fullaccess" | "viewOnly";
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ClientDashboardController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            currentPage: 1,
            totalPages: 15,
            showProfile: false,
            profileAccess: "viewOnly"
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    
    async componentDidMount() {
        super.componentDidMount();
        const getToken = await getStorageData("authToken");
        if(!getToken) {
          this.handleNavigation("Home");
        }
    };

    handleNavigation =(route: string)=>{
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), route);
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
    };
    
    handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
        this.setState({
            currentPage: page
        });
    };

    handleShowProfile = () => {
        this.setState({
            showProfile: true
        });
    };
    // Customizable Area End
}