import React, { Component } from "react";
import {
    Box,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    styled,
    Pagination,
    PaginationProps,
    Menu,
    IconButton,
    Button
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileView from "./FileView";

interface FolderFile {
    fileId?: number;
    file: {
        name: string;
        size: string
    };
    uploadDate?: string;
    issueDate?: string;
    lastUpdate?: string;
    status?: string;
    uploadBy?: string;
    dateSigned? : string;
};

interface StyledPaginationProps extends PaginationProps {
    totalPages: number;
}

interface Props {
    folderData: {
        folderName: string;
        folderFiles: FolderFile[];
        currentPage: number;
        totalPages: number;
    };
    backClick: ()=>void;
    handlePageChange: (page: number)=>void;
    showPoliciesFolder: boolean;
    loading: boolean;
    handleFileClick: ()=>void;
    handleUploadButtonClick: (event: React.ChangeEvent<HTMLInputElement>)=>void;
    handleMenuClick: (clickOn: string, fileId: number)=>void;
};

interface S {
    anchorEl: HTMLElement | null;
    selectedFileId: number | null
};

interface SS {
};

export default class InsideFolder extends Component<Props, S, SS> {
    
    fileInputRef = React.createRef<HTMLInputElement>();
    
    constructor(props: Props){
        super(props);
        this.state = {
            anchorEl: null,
            selectedFileId: null
        }
    };

    static defaultProps: Partial<Props> = {
        showPoliciesFolder: false,
        loading: false,
        handleFileClick: () => {},
        handleUploadButtonClick: ()=> {},
        handleMenuClick: ()=>{}
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.folderData !== prevProps.folderData) {
            this.setState({ anchorEl: null });
        }
    };

    handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
        this.props.handlePageChange(page);
    };

    handleMenuOpen = (event: React.MouseEvent<HTMLElement>, fileId: number) => {
        this.setState({ 
            anchorEl: event.currentTarget,
            selectedFileId: fileId
        });
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null,
            selectedFileId: null
        });
    };

    handleStatusStyle = (status: string) => {
        let color = "#EF4444", background = "#FEF2F2" , border = "1px solid #FECACA";
        if (status === "overdue") {
            color = "#D97706";
            background = "#FFFBEB";
            border = "1px solid #FDE68A";
        }
        if (status === "signed") {
            color = "#059669";
            background = "#ECFDF5";
            border = "1px solid #A7F3D0";
        }
        return {
            color,
            background,
            border
        }
    };

    handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files;
        if (selectedFiles) {
          await this.props.handleUploadButtonClick(event);
        }
        if (this.fileInputRef.current) {
          this.fileInputRef.current.value = "";
        }
    };

    renderPaginationRow = () => {
        const { folderData } = this.props;
        return (
            <TableRow>
                <TableCell colSpan={5}>
                    <Box style={webStyle.paginationMainBox}>
                        <Typography style={webStyle.pageText}>
                            Page {folderData.currentPage} of {folderData.totalPages }
                        </Typography>
                        <StyledPagination
                            data-test-id="paginationTestId"
                            page={folderData.currentPage}
                            count={folderData.totalPages}
                            variant="outlined"
                            shape="rounded"
                            totalPages={folderData.totalPages}
                            onChange={this.handlePageChange}
                        />
                    </Box>
                </TableCell>
            </TableRow>
        );
    };

    renderMenu = () => {
        const { anchorEl, selectedFileId } = this.state;
        const { handleMenuClick } = this.props;
        return (
            <Menu
                open={Boolean(anchorEl) && Boolean(selectedFileId)}
                id="basic-menu"
                data-test-id="publishMenuTestID"
                anchorEl={anchorEl}
                disableScrollLock={true}
                onClose={this.handleMenuClose}
                elevation={0}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal:'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                slotProps={{
                    paper: {
                        style: {
                            overflow: 'visible',
                            boxShadow: '0px 0px 8px 4px #00000008',
                            borderRadius: "16px",
                            border: "1px solid #CBD5E1"
                        }
                    }
                }}
            >
                <Box style={webStyle.menuBox}>
                    <MenuItemBox
                        onClick={() => handleMenuClick("download", Number(selectedFileId))}
                    >
                        <FileDownloadOutlinedIcon/>
                        <Typography>
                            Download
                        </Typography>
                    </MenuItemBox>
                    <MenuItemBox>
                        <EditOutlinedIcon/>
                        <Typography>
                            Manage
                        </Typography>
                    </MenuItemBox>
                    <MenuItemBox
                        data-test-id="menuDeleteButtonTestId"
                        onClick={() => handleMenuClick("delete", Number(selectedFileId))}
                    >
                        <DeleteOutlineRoundedIcon style={{color: "#DC2626"}}/>
                        <Typography style={{color: "#DC2626"}}>
                            Delete
                        </Typography>
                </MenuItemBox>
                </Box>
            </Menu>
        )
    };

    render() {
        const { folderData, loading, backClick, showPoliciesFolder, handleFileClick } = this.props;
        return (
            <Box>
                <Box style={webStyle.topHeadingBox}>
                    <Box style={webStyle.headingBox}>
                        <ArrowBackIcon
                            data-test-id="backArrowButtonTestId"
                            style={webStyle.backArrow}
                            onClick={backClick}
                        />
                        <Typography style={webStyle.headingText}>{folderData.folderName}</Typography>
                    </Box>
                    <UploadButton style={webStyle.buttonStyle} >
                        <Box style={webStyle.buttonInnerBox} component={"label"}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 11.575C7.71667 11.575 7.47933 11.479 7.288 11.287C7.096 11.0957 7 10.8583 7 10.575V3.425L5.125 5.3C4.925 5.5 4.69167 5.6 4.425 5.6C4.15833 5.6 3.91667 5.49167 3.7 5.275C3.5 5.075 3.40433 4.83733 3.413 4.562C3.421 4.28733 3.51667 4.05833 3.7 3.875L7.3 0.275C7.4 0.175 7.50833 0.104 7.625 0.0619998C7.74167 0.0206665 7.86667 0 8 0C8.13333 0 8.25833 0.0206665 8.375 0.0619998C8.49167 0.104 8.6 0.175 8.7 0.275L12.3 3.875C12.5 4.075 12.5957 4.31233 12.587 4.587C12.579 4.86233 12.4833 5.09167 12.3 5.275C12.1 5.475 11.8627 5.579 11.588 5.587C11.3127 5.59567 11.075 5.5 10.875 5.3L9 3.425V10.575C9 10.8583 8.90433 11.0957 8.713 11.287C8.521 11.479 8.28333 11.575 8 11.575ZM2 15.575C1.45 15.575 0.979333 15.3793 0.588 14.988C0.196 14.596 0 14.125 0 13.575V11.575C0 11.2917 0.0956668 11.054 0.287 10.862C0.479 10.6707 0.716667 10.575 1 10.575C1.28333 10.575 1.521 10.6707 1.713 10.862C1.90433 11.054 2 11.2917 2 11.575V13.575H14V11.575C14 11.2917 14.096 11.054 14.288 10.862C14.4793 10.6707 14.7167 10.575 15 10.575C15.2833 10.575 15.5207 10.6707 15.712 10.862C15.904 11.054 16 11.2917 16 11.575V13.575C16 14.125 15.8043 14.596 15.413 14.988C15.021 15.3793 14.55 15.575 14 15.575H2Z" fill="white"/>
                            </svg>
                            <Typography style={webStyle.buttonText}>Upload</Typography>
                            <input
                                ref={this.fileInputRef}
                                multiple
                                data-test-id="uploadFileInputTestId"
                                type="file"
                                hidden
                                onChange={(event)=>this.handleFileChange(event)}
                            />
                        </Box>
                    </UploadButton>
                </Box>
                <Box>
                    <StyledTableContainer>
                        <Table>
                            <StyledTableHead>
                                <TableRow>
                                    <TableCell>
                                        <Box style={webStyle.nameHeadingCell}>
                                            {
                                                <Box style={webStyle.checkBoxNotSelect}>
                                                </Box>
                                            }
                                            Name
                                        </Box>
                                    </TableCell>
                                    {
                                        showPoliciesFolder?
                                        <>
                                            <TableCell>Issue date</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Date signed</TableCell>
                                        </>:
                                        <>
                                            <TableCell>Date uploaded</TableCell>
                                            <TableCell>Last updated</TableCell>
                                            <TableCell>Uploaded by</TableCell>
                                        </>
                                    }
                                    <TableCell></TableCell>
                                </TableRow>
                            </StyledTableHead>
                            {
                                (folderData.folderFiles.length === 0)?
                                <StyledTableBody>
                                    <TableCell colSpan={5}>

                                    <Box style={webStyle.noFileBox}>
                                        <Typography style={webStyle.noFileText}>
                                        {loading? "Loading...": "No file is available !"}
                                        </Typography>
                                    </Box>
                                    </TableCell>
                                </StyledTableBody>:
                                <StyledTableBody>
                                    {folderData.folderFiles.map((item, index) => (
                                        <TableRow key={index}>
                                        <TableCell>
                                            <Box style={webStyle.tableFileBox}>
                                                <Box style={webStyle.checkBoxNotSelect}>
                                                </Box>
                                                <Box
                                                    data-test-id="fileBoxTestId"
                                                    style={webStyle.fileViewWrapper} 
                                                    onClick={handleFileClick}
                                                >
                                                    <FileView name={item.file.name} size={item.file.size}/>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                        {
                                            showPoliciesFolder?
                                            <>
                                                <TableCell>{item.issueDate}</TableCell>
                                                <TableCell>
                                                    <Typography style={{...webStyle.statusCell, ...this.handleStatusStyle(`${item.status}`)}}>
                                                        {
                                                            item.status === "signed"?
                                                            "Policy Signed": 
                                                            <>
                                                            {
                                                                item.status === "overdue"?
                                                                "Overdue": "Policy Not Signed"
                                                            }
                                                            </>
                                                        }
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>{item.dateSigned}</TableCell>
                                            </>:
                                            <>
                                                <TableCell>{item.uploadDate}</TableCell>
                                                <TableCell>{item.lastUpdate}</TableCell>
                                                <TableCell>{item.uploadBy}</TableCell>
                                            </>
                                        }
                                        <TableCell style={{width: "28px"}}>
                                            <IconButton
                                                data-test-id="menuMoreIconButtonTestId"
                                                onClick={(event) => this.handleMenuOpen(event, Number(item.fileId))}
                                            >
                                                <MoreVertIcon style={webStyle.moreVertIcon}/>
                                            </IconButton>
                                        </TableCell>
                                        </TableRow>
                                    ))}
                                    {this.renderPaginationRow()}
                                </StyledTableBody>
    }
                        </Table>
                    </StyledTableContainer>
                </Box>
                {this.renderMenu()}
            </Box>
        );
    }
};

const StyledTableContainer = styled(TableContainer)({
    border: "1px solid #E2E8F0",
    borderRadius: "25px",
    overflow: "hidden",
    background: "white"
});
  
const StyledTableHead = styled(TableHead)({
    "& .MuiTableRow-root": {
      backgroundColor: "#F8FAFC"
    },
    "& .MuiTableCell-root": {
      borderRight: "1px solid #E2E8F0",
      borderBottom: "1px solid #E2E8F0",
      color: "#64748B",
      fontSize: "12px",
      fontWeight: 500
    },
    "& .MuiTableCell-root:last-child": {
      borderRight: "none"
    }
});

const StyledTableBody = styled(TableBody)({
    "& .MuiTableCell-root": {
        borderRight: "1px solid #E2E8F0",
        borderBottom: "1px solid #E2E8F0",
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: 500
    },
    "& .MuiTableCell-root:last-child": {
      borderRight: "none"
    }
});

const StyledPagination = styled(Pagination)<StyledPaginationProps>(({ totalPages }) => ({
    "& .MuiPaginationItem-root": {
        margin: "0",
        border: "1px solid #E5E5E5",
        borderRadius: "0px",
        height: "32px",
        boxShadow: "0px 4px 8px -5px #00000026",
        color: "#0F172A",
        borderRight: "1px solid #E2E8F0",
        borderLeft: "none",
        fontWeight: 600,
        fontSize: "12px"
    },
    "& .MuiPaginationItem-root[aria-label='Go to next page']": {
        marginLeft: "10px",
        borderRadius: "8px",
        border: "1px solid #E5E5E5"
    },
    "& .MuiPaginationItem-root[aria-label='Go to previous page']": {
        marginRight: "10px",
        borderRadius: "8px",
        border: "1px solid #E5E5E5"
    },
    "& .MuiPaginationItem-root[aria-label$='page 1']": {
        borderRadius: "8px 0 0 8px",
        borderLeft: "1px solid #E5E5E5"
    },
    [`& .MuiPaginationItem-root[aria-label$='page ${totalPages}']`]: {
        borderRadius: totalPages === 1? "8px": "0 8px 8px 0",
        borderRight: "1px solid #E5E5E5"
    },
    "& .MuiPaginationItem-root:hover": {
        background: "#E2E8F0"
    },
    "& .MuiPaginationItem-root.Mui-selected": {
        background: "#E2E8F0"
    }
}));

const MenuItemBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 600,
    color: "#0F172A",
    gap: "8px",
    width: "150px",
    padding: "12px 16px",
    borderRadius: "8px",
    border: "1px solid transparent",
    cursor: "pointer",
    "&:hover": {
        background: "#F8FAFC",
        borderColor: "#CBD5E1"
    },
    "& .MuiSvgIcon-root": {
        fontSize: "20px"
    },
    "& .MuiTypography-root": {
        fontSize: "14px",
        fontWeight: 600,
        color: "#0F172A",
        marginTop: "5px"
    }
});

const UploadButton = styled(Button)({
    background: "#6366F1",
    "&:hover": {
        background: "#6366F1"  
    },
    "& .MuiTypography-root": {
        fontWeight: 600,
        marginLeft: "10px",
        color: "#FFFFFF"
    }
});

const webStyle = {
    headingBox: {
        display: "flex",
        gap: "20px"
    },
    buttonText: {
        paddingTop: "5px"
    },
    buttonStyle: {
        height: "44px",
        textTransform: "none",
        color: "#4F46E5",
        borderRadius: "100px",
        padding: "0 16px"
    } as React.CSSProperties,
    topHeadingBox: {
        display: "flex",
        marginBottom: "24px",
        justifyContent: "space-between",
        alignItems: "center"
    },
    noFileBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "400px"
    },
    noFileText: {
        fontSize: "30px",
        color: "#94A3B8",
        fontWeight: 600
    },
    buttonInnerBox: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer"
    },
    statusCell: {
        fontSize: "12px",
        fontWeight: 500,
        borderRadius: "6px",
        padding: "4px 8px",
        width: "fit-Content"       
    },
    fileViewWrapper: {
        width: "100%",
        cursor: "pointer"
    },
    backArrow: {
        fontSize: "30px",
        color: "#0F172A",
        cursor: "pointer"
    },
    headingText: {
        fontSize: "24px",
        fontWeight: 600,
        color: "#0F172A"
    },
    moreVertIcon: {
        fontSize: "20px",
        color: "#475569",
        cursor: "pointer"
    },
    checkBoxNotSelect: {
        border: "1px solid #CBD5E1",
        background: "#FFFFFF",
        borderRadius: "6px",
        width: "20px",
        height: "20px",
        overflow: "hidden",
        cursor: "pointer"
    },
    nameHeadingCell: {
        display: "flex",
        gap: "15px"
    },
    checkBoxIconStyle: {
        marginTop: "-4px",
        marginLeft: "-4px",
        fontSize: "28px",
        color: "#3B82F6"
    },
    tableFileBox: {
        display: "flex",
        gap: "15px",
        alignItems: "center"
    },
    paginationMainBox: {
        background: "white",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    pageText: {
        fontSize: "14px",
        color: "#0F172A",
        fontWeight: 500
    },
    menuBox: {
        margin: "5px 15px"
    }
};