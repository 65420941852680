import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    styled,
    Button,
    Checkbox,
    IconButton,
    Modal,
    TextField,
    Snackbar,
    Alert,
    Pagination,
    PaginationProps
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import Drawer from "../../dashboard/src/Drawer.web";
import PageHeader from "../../../components/src/PageHeader";
import FolderView from "../../../components/src/FolderView";
import InsideFolder from "../../../components/src/InsideFolder";
// Customizable Area End

import PoliciesController, {
    Props,
    configJSON,
} from "./PoliciesController";

// Customizable Area Start
const folders = [
    {name: "Complaints Handling", owner: "", created: "", fileCount: "152", color: "#FBBF24"},
    {name: "Conduct of Business and Code of Conduct", owner: "me", created: "10 Mar", fileCount: "16", color: "#34D399"},
    {name: "Cross Border Marketing", owner: "Alex Stevenson", created: "10 Mar", fileCount: "20", color: "#FBBF24"},
    {name: "Conflicts of Interest and Register", owner: "Jane Doe", created: "10 Sep", fileCount: "37", color: "#F87171"},
    {name: "Fraud", owner: "Jane Doe", created: "10 Sep", fileCount: "37", color: "#F87171"},
    {name: "Dawn Raids", owner: "me", created: "24 Nov", fileCount: "152", color: "#10B981"},
    {name: "Governance and Training", owner: "me", created: "10 Mar", fileCount: "16", color: "#34D399"},
    {name: "Information Technology", owner: "Alex Stevenson", created: "10 Mar", fileCount: "20", color: "#10B981"},
    {name: "PA Dealing", owner: "me", created: "24 Nov", fileCount: "152", color: "#FBBF24"},
    {name: "Whistleblowing", owner: "Alex Stevenson", created: "10 Mar", fileCount: "20", color: "#F87171"},
    {name: "Policy Management", owner: "Jane Doe", created: "10 Sep", fileCount: "37", color: "#F87171"},
    {name: "Treating Customer Fairly, Vulnerable Clients and Consumer Duty", owner: "me", created: "10 Mar", fileCount: "16", color: "#34D399"},
    {name: "Tech", owner: "me", created: "24 Nov", fileCount: "152", color: "#FBBF24"},
    {name: "Stock market", owner: "Jane Doe", created: "10 Sep", fileCount: "37", color: "#FBBF24"},
    {name: "EFF Governance", owner: "me", created: "10 Mar", fileCount: "16", color: "#34D399"},
    {name: "IT", owner: "Alex Stevenson", created: "10 Mar", fileCount: "20", color: "#FBBF24"}
];

const colorArray = [ "#FBBF24", "#34D399", "#F87171", "#10B981", "#FBBF24", "#34D399", "#FBBF24", "#FBBF24", "#3B82F6", "#FBBF24" ];

interface CustomStylePaginationProps extends PaginationProps {
    totalPages: number;
};
// Customizable Area End

export default class Policies extends PoliciesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderPolicy = () => {
        return (
            <Box>
                <Box style={webStyle.policyHeadingBox}>
                    <ArrowBackIcon
                        data-test-id="policyBackArrowButtonTestId"
                        style={webStyle.backArrow}
                        onClick={this.handlePolicyBackClick}
                    />
                    <Typography style={webStyle.headingText}>Company Policy 1.0</Typography>
                </Box>
                <Box style={webStyle.policyViewWrapper}>
                    <Box style={webStyle.policyViewBox}>
                        <Typography style={webStyle.viewHeading}>
                            Company Policy 1.0
                        </Typography>
                        <Typography variant="body2" mb={3}>
                            Welcome to our Company Policy page. Here, we outline the guidelines and standards that govern our business practices and interactions with employees, customers, partners, and stakeholders. We are committed to maintaining a transparent, ethical, and responsible environment in all aspects of our operations.
                        </Typography>
                        {[
                            {heading: "Code of Conduct", desc: "Welcome to our Company Policy page. Here, we outline the guidelines and standards that govern our business practices and interactions with employees, customers, partners, and stakeholders. We are committed to maintaining a transparent, ethical, and responsible environment in all aspects of our operations."},
                            {heading: "Equal Opportunity and Diversity", desc: "Our company is committed to providing equal opportunities and promoting diversity in the workplace. We do not discriminate based on race, gender, age, religion, sexual orientation, disability, or other legally protected characteristics."},
                            {heading: "Privacy and Data Protection", desc: "We respect your privacy and are dedicated to safeguarding personal information. Our data protection policies are designed to comply with relevant laws and to ensure that your information is handled securely and responsibly."},
                            {heading: "Health and Safety", desc: "Maintaining a safe and healthy work environment is a top priority. We adhere to all regulatory health and safety standards and continuously work to enhance workplace safety."},
                            {heading: "Anti-Bribery and Anti-Corruption", desc: "Our company has zero tolerance for bribery or corruption in any form. We strictly prohibit offering, giving, receiving, or soliciting anything of value to improperly influence actions or decisions."},
                            {heading: "Environmental Responsibility", desc: "We are committed to minimizing our environmental impact by promoting sustainable practices, reducing waste, and working towards eco-friendly initiatives."}
                        ].map((value, index)=>
                            <React.Fragment key={index}>
                                <Typography variant="body1" style={{fontWeight: 600}} mb={1.5}>
                                    {`${index+1}. ${value.heading}`}
                                </Typography>
                                <Typography variant="body2" mb={3}>
                                    {value.desc}
                                </Typography>
                            </React.Fragment>
                        )}
                    </Box>
                    <Box style={webStyle.checkBoxBox}>
                        <CustomCheckbox 
                            checkedIcon={
                                <span style={webStyle.checkIconStyle}>
                                    <CheckRoundedIcon />
                                </span>
                            }
                            icon={
                                <span style={webStyle.unCheckIconStyle}/>
                            }
                        />
                        <Typography variant="body2" style={{paddingTop: "5px"}}>
                            I have read and agree to comply with the policy.
                        </Typography>
                    </Box>
                    <SubmitButton style={{width: "230px"}}>
                        Submit
                    </SubmitButton>
                </Box>
            </Box>
        );
    };

    renderInputDeleteModal = () => {
        const { modalOpenOn, folderNameInput, folderNameAddErr, modalHeadingText } = this.state;
        return(
            <Modal
                open={Boolean(modalOpenOn)}
                onClose={this.handleModalOnClose}
            >
                <Box
                    style={webStyle.modBoxStyle}
                >
                    <Box
                        style={webStyle.modInnerBoxStyle}
                    >
                        <Box style={webStyle.modInnerTopBox}>
                            <Typography style={webStyle.addFolderMod}>{modalHeadingText}</Typography>
                            <IconButton
                                data-test-id="modalCloseIconTestId"
                                onClick={()=>this.handleModalOnClose()}
                            >
                                <CloseRoundedIcon/>
                            </IconButton>
                        </Box>
                        {   (modalOpenOn === "addFolder" || modalOpenOn === "renameFolder") &&
                            <Box style={webStyle.modInnerMidBox}>
                                <Typography style={webStyle.InputLabelStyle}>Please enter folder name:</Typography>
                                <TextField
                                    required
                                    type="text"
                                    variant="outlined"
                                    placeholder="Enter folder name"
                                    value= { folderNameInput }
                                    onChange={ this.handleFolderNameInputChange }
                                    data-test-id = "addFolderInputTestId"
                                    autoComplete="off"
                                    style={{ width: '100%' }}
                                    inputProps={{ 
                                        autoComplete: 'new-password',
                                        sx: {
                                            "&::placeholder": {
                                                color: "#94A3B8",
                                                fontSize: "16px"
                                            }
                                        }
                                    }}
                                    InputProps={{
                                        style: { borderRadius: '50px', height: '40px' }
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: folderNameAddErr ? "#F87171" :"#CBD5E1"
                                            },
                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                borderColor: folderNameAddErr ? "#F87171" :"#CBD5E1"
                                            },
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                border: folderNameAddErr ? "1px solid #F87171" :"none",
                                                boxShadow: folderNameAddErr ? "none": '0 0 0 1px #4F46E5, 0 0 0 2px #C7D2FE'
                                            }
                                        },
                                        "& input:-webkit-autofill": {
                                            WebkitBoxShadow: "0 0 0px 1000px #FFFFFF inset"
                                        }
                                    }}
                                />
                                {
                                    folderNameAddErr && 
                                    <Typography style={webStyle.errorText}>
                                        {folderNameAddErr}
                                    </Typography>
                                }
                            </Box>
                        }
                        <Box
                            style={webStyle.modInnerLastBox}
                        >
                            <CancelButton
                                data-test-id="modalCancelButtonTestId"
                                style = {{width: "100px"}}
                                onClick={()=>this.handleModalOnClose()}
                            >
                                Cancel
                            </CancelButton>
                            {
                                (modalOpenOn === "addFolder" || modalOpenOn === "renameFolder")?
                                <OkButton
                                    data-test-id = "modalChangeButtonTestId"
                                    style = {{width: "135px"}}
                                    onClick = {()=> this.handleModalSubmitButton()}
                                    disabled = {folderNameInput.length === 0}
                                >
                                    {modalOpenOn === "addFolder"? "Add Folder": "Change"}
                                </OkButton>:
                                <DeleteButton
                                    data-test-id="modalDeleteButtonTestId"
                                    style = {{width: "135px"}}
                                    onClick = {()=> modalOpenOn === "deleteFile"?
                                        this.deleteFolderFile(Number(this.state.fileId)):
                                        this.deleteFolderApiCall(Number(this.state.folderId))
                                    }
                                >
                                    Delete
                                </DeleteButton>
                            } 
                        </Box>
                    </Box>
                </Box>
            </Modal>
        );
    };

    renderSuccSnackbar = () => {
        const { snackbarOpenFor, snackbarSuccMsg } = this.state;
        return(
            <Snackbar
                open={snackbarOpenFor === "success"}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={4000}
                onClose={this.handleSnackbarOnClose}
            >
                <Alert
                    onClose={this.handleSnackbarOnClose}
                    severity = "success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    { snackbarSuccMsg }
                </Alert>
            </Snackbar>
        )
    };

    renderErrSnackbar = () => {
        const { snackbarOpenFor, snackbarErrMsg } = this.state;
        return(
            <Snackbar
                data-test-id="snackbarTestId"
                open={snackbarOpenFor === "error"}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={4000}
                onClose={this.handleSnackbarOnClose}
            >
                <Alert
                    onClose={this.handleSnackbarOnClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    { snackbarErrMsg }
                </Alert>
            </Snackbar>
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            openView,
            selectFolderName,
            page,
            folderList,
            loading,
            folCurrentPage,
            folTotalPages,
            folderFiles
        } = this.state;
        const folderData = {
            folderName: selectFolderName,
            folderFiles: folderFiles,
            currentPage: page,
            totalPages: folTotalPages
        };
        // Customizable Area End
        return (
            // Customizable Area Start
            <Drawer navigation={this.props.navigation} pageName = "Policies">
                <Box style={webStyle.mainBox}>
                    {
                        openView === "folders" &&
                        <>
                            <PageHeader
                                data-test-id="pageHeaderTestId"
                                heading={configJSON.Policies}
                                handleAddFolder={()=>this.handleModalOpenFor("addFolder", 0)}
                            />
                            <Box style={webStyle.folderMainBox}>
                                <Typography style={webStyle.folderText}>
                                    {configJSON.Folders}
                                </Typography>
                                {folderList.length === 0?
                                    <Box
                                        style={webStyle.noFolderBox}
                                    >
                                        <Typography
                                            style={webStyle.noFolderText}
                                        >
                                            {loading? "Loading...": "No folder is available !"}
                                        </Typography>
                                    </Box>:
                                    <>
                                        <Grid container spacing={3} style={webStyle.folderContainerGrid}>
                                            {folderList.map((item, index) => 
                                                <Grid
                                                    data-test-id={`folderGrid${index}TestId`}
                                                    item 
                                                    sm={4}
                                                    md={3}
                                                    key={index}
                                                >
                                                    <Box
                                                        data-test-id={`folderBoxTestId${index}`}
                                                        onClick={() => this.handleFolderOpen(item.attributes.folder_name, Number(item.id))}
                                                    >
                                                        <FolderView
                                                            id={Number(item.id)}
                                                            data-test-id={`folderTestId${index}`}
                                                            folderName={item.attributes.folder_name}
                                                            fileCount={item.attributes.total_files? `${item.attributes.total_files}`: "0"}
                                                            iconColor={colorArray[index]}
                                                            showOwner={Boolean(folders[index].owner)}
                                                            owner={folders[index].owner}
                                                            created={this.convertToDayAndMonth(item.attributes.created_at)}
                                                            handleMenuClick = {this.handleFolderMenuItemButtonClick}
                                                        />
                                                    </Box>
                                                </Grid>
                                            )}
                                        </Grid>
                                        <Box style={webStyle.paginationBox}>
                                            <Typography style={webStyle.pageText}>
                                                Page { folCurrentPage } of { folTotalPages }
                                            </Typography>
                                            <CustomPaginationStyle
                                                data-test-id="folderPaginationTestId"
                                                page={folCurrentPage}
                                                count={folTotalPages}
                                                variant="outlined"
                                                shape="rounded"
                                                totalPages={folTotalPages}
                                                onChange={this.handleFolderPageChange}
                                            />
                                        </Box>
                                    </>
                                }
                            </Box>
                        </>
                    }
                    {
                        openView === "files" &&
                        <InsideFolder
                            data-test-id="insideFolderTestId"
                            folderData = {folderData}
                            loading = {loading}
                            backClick={this.handleFolderClose}
                            handlePageChange={(page)=> this.handlePageChange(page)}
                            showPoliciesFolder = {true}
                            handleFileClick={this.handlePolicyOpen}
                            handleUploadButtonClick={this.fileUploadApi}
                            handleMenuClick={this.handleFileMenuOpenFor}
                        />
                    }
                    {
                        openView === "policy" &&
                        this.renderPolicy()
                    }
                </Box>
                {this.renderInputDeleteModal()}
                {this.renderErrSnackbar()}
                {this.renderSuccSnackbar()}
            </Drawer>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const SubmitButton = styled(Button)({
    background: "#6366F1",
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: 600,
    padding: "8px",
    borderRadius: "100px",
    textTransform: "none",
    "&:hover": {
        background: "#6366F1"  
    },
    "& .MuiTypography-root": {
        fontWeight: 600,
        marginLeft: "10px",
        color: "#FFFFFF"
    }
});

const DeleteButton = styled(Button)({
    background: "#DC2626",
    borderRadius: "100px",
    padding: "10px 16px",
    color: "#FFFFFF",
    fontWeight: 600,
    textTransform: "none",
    "&:hover": {
        background: "#DC2626",
        color: "#FFFFFF"
    },
    "&:disabled": {
        background: "#DC2626", 
        color: "#FFFFFF",
        opacity: 0.6,
        pointerEvents: "none"
    }
});

const CustomPaginationStyle = styled(Pagination)<CustomStylePaginationProps>(({ totalPages }) => ({
    [`& .MuiPaginationItem-root[aria-label$='page ${totalPages}']`]: {
        borderRadius: totalPages === 1? "8px": "0 8px 8px 0",
        borderRight: "1px solid #E5E5E5"
    },
    "& .MuiPaginationItem-root": {
        margin: "0",
        boxShadow: "0px 4px 8px -5px #00000026",
        color: "#0F172A",
        borderRight: "1px solid #E2E8F0",
        borderLeft: "none",
        fontWeight: 600,
        border: "1px solid #E5E5E5",
        borderRadius: "0px",
        fontSize: "12px",
        height: "32px",
        background: "#FFFFFF"
    },
    "& .MuiPaginationItem-root[aria-label='Go to previous page']": {
        marginRight: "10px",
        borderRadius: "8px",
        border: "1px solid #E5E5E5"
    },
    "& .MuiPaginationItem-root[aria-label='Go to next page']": {
        marginLeft: "10px",
        border: "1px solid #E5E5E5",
        borderRadius: "8px"
    },
    "& .MuiPaginationItem-root[aria-label$='page 1']": {
        borderRadius: "8px 0 0 8px",
        borderLeft: "1px solid #E5E5E5"
    },
    "& .MuiPaginationItem-root.Mui-selected": {
        background: "#E2E8F0"
    },
    "& .MuiPaginationItem-root:hover": {
        background: "#E2E8F0"
    }
}));

const CancelButton = styled(Button)({
    background: "#EEF2FF",
    borderRadius: "100px",
    padding: "10px 16px",
    color: "#6366F1",
    fontWeight: 600,
    textTransform: "none",
    "&:hover": {
        background: "#EEF2FF",
        color: "#6366F1"
    }
});

const OkButton = styled(Button)({
    background: "#6366F1",
    borderRadius: "100px",
    padding: "10px 16px",
    color: "#FFFFFF",
    fontWeight: 600,
    textTransform: "none",
    "&:hover": {
        background: "#6366F1",
        color: "#FFFFFF"
    },
    "&:disabled": {
        background: "#6366F1", 
        color: "#FFFFFF",
        opacity: 0.6,
        pointerEvents: "none"
    }
});

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    "& .MuiSvgIcon-root": {
      fontSize: 22,
    },
    "&.Mui-checked": {
      "& .MuiSvgIcon-root": {
        borderRadius: "6px",
        backgroundColor: "#4F46E5",
        color: "white"
      }
    },
    "&:not(.Mui-checked)": {
      "& .MuiSvgIcon-root": {
        color: "white"
      }
    }
}));

const webStyle = {
    mainBox: {
        background: "#fafaf9",
        padding: "24px",
        height: "100%"
    },
    modBoxStyle: {
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    addFolderMod: {
        fontSize: "18px",
        color: "#0D0D12",
        fontWeight: 600
    },
    pageText: {
        fontSize: "14px",
        color: "#0F172A",
        fontWeight: 500
    },
    paginationBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height: "40px",
        marginTop: "30px"
    },
    errorText: {
        color: "#DC2626",
        fontSize: "12px",
        marginTop: "5px"
    },
    modInnerLastBox: {
        padding: "24px",
        display: "flex",
        justifyContent: "flex-end",
        gap: "12px"
    },
    modInnerTopBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "24px 24px 12px"
    },
    InputLabelStyle: {
        fontSize: "14px",
        fontWeight: 600,
        color: "#475569",
        marginBottom: "3px"
    },
    modInnerMidBox: {
        padding: "24px",
        borderTop: "1px solid #E2E8F0",
        borderBottom: "1px solid #E2E8F0"
    },
    checkIconStyle: {
        display: "inline-block",
        width: 22,
        height: 22,
        borderRadius: "6px",
        backgroundColor: "#4F46E5"
    },
    modInnerBoxStyle: {
        background: "#FFFFFF",
        borderRadius: "16px",
        width: "480px"
    },
    noFolderText: {
        fontSize: "30px",
        color: "#94A3B8",
        fontWeight: 600
    },
    noFolderBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
        height: "300px"
    },
    checkBoxBox: {
        display: "flex",
        alignItems: "center",
        marginLeft: "-8px",
        gap: "5px"
    },
    unCheckIconStyle: {
        display: "inline-block",
        width: 22,
        height: 22,
        borderRadius: "6px",
        backgroundColor: "white",
        border: "1px solid #CBD5E1",
        boxSizing: "border-box"
    } as React.CSSProperties,
    viewHeading: {
        color: "#0F172A",
        fontSize: "32px",
        fontWeight: 600,
        marginBottom: "20px"
    },
    folderContainerGrid: {
        marginTop: "20px"
    },
    folderMainBox: {
        marginTop: "25px"
    },
    folderText: {
        color: "#0F172A",
        fontSize: "20px",
        fontWeight: 600
    },
    policyHeadingBox: {
        display: "flex",
        gap: "20px",
        marginBottom: "24px"
    },
    backArrow: {
        fontSize: "30px",
        color: "#0F172A",
        cursor: "pointer"
    },
    headingText: {
        fontSize: "24px",
        fontWeight: 600,
        color: "#0F172A"
    },
    policyViewWrapper: {
        width: "700px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        gap: "30px"
    } as React.CSSProperties,
    policyViewBox: {
        padding: "25px",
        borderRadius: "12px",
        border: "1px solid #E5E5E5",
        color: "#000000",
        background: "white"
    }
};
// Customizable Area End
