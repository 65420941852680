import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    Snackbar,
    Alert,
    Modal,
    TextField,
    IconButton,
    styled,
    Button,
    Pagination,
    PaginationProps
} from "@mui/material";
import Drawer from "../../dashboard/src/Drawer.web";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// Customizable Area End

import FormsController, {
    Props,
    configJSON,
} from "./FormsController";
import PageHeader from "../../../components/src/PageHeader";
import FolderView from "../../../components/src/FolderView";
import InsideFolder from "../../../components/src/InsideFolder";

// Customizable Area Start
const colorArray = [ "#FBBF24", "#F87171", "#34D399", "#FBBF24", "#34D399", "#FBBF24", "#FBBF24", "#3B82F6", "#FBBF24" ];

interface StyledPaginationProps extends PaginationProps {
    totalPages: number;
};
// Customizable Area End

export default class Forms extends FormsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderSuccessSnackbar = () => {
        const { snackbar, succSnackbarMsg } = this.state;
        return(
            <Snackbar
                open={snackbar === "success"}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={4000}
                onClose={this.handleSnackbarClose}
            >
                <Alert
                    onClose={this.handleSnackbarClose}
                    severity = "success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    { succSnackbarMsg }
                </Alert>
            </Snackbar>
        )
    };

    renderErrorSnackbar = () => {
        const { snackbar, tokenErr } = this.state;
        return(
            <Snackbar
                data-test-id="snackbarTestId"
                open={snackbar === "error"}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={4000}
                onClose={this.handleSnackbarClose}
            >
                <Alert
                    onClose={this.handleSnackbarClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    { tokenErr }
                </Alert>
            </Snackbar>
        )
    };

    renderFolderNameInputModal = () => {
        const { modalOpen, folderNameAdd, folderAddErr, modalHeading } = this.state;
        return(
            <Modal
                open={Boolean(modalOpen)}
                onClose={this.handleAddFolderModalClose}
            >
                <Box
                    style={webStyle.modBoxStyle}
                >
                    <Box
                        style={webStyle.modInnerBoxStyle}
                    >
                        <Box style={webStyle.modInnerTopBox}>
                            <Typography style={webStyle.addFolderMod}>{modalHeading}</Typography>
                            <IconButton
                                data-test-id="modalCloseIconTestId"
                                onClick={()=>this.handleAddFolderModalClose()}
                            >
                                <CloseRoundedIcon/>
                            </IconButton>
                        </Box>
                        {   (modalOpen === "addFolder" || modalOpen === "renameFolder") &&
                            <Box style={webStyle.modInnerMidBox}>
                                <Typography style={webStyle.labelStyle}>Please enter folder name:</Typography>
                                <TextField
                                    required
                                    type="text"
                                    variant="outlined"
                                    placeholder="Enter folder name"
                                    value= { folderNameAdd }
                                    onChange={ this.handleFolderAddName }
                                    data-test-id = "addFolderInputTestId"
                                    autoComplete="off"
                                    style={{ width: '100%' }}
                                    inputProps={{ 
                                        autoComplete: 'new-password',
                                        sx: {
                                            "&::placeholder": {
                                                color: "#94A3B8",
                                                fontSize: "16px"
                                            }
                                        }
                                    }}
                                    InputProps={{
                                        style: { borderRadius: '50px', height: '40px' }
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: folderAddErr ? "#F87171" :"#CBD5E1"
                                            },
                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                borderColor: folderAddErr ? "#F87171" :"#CBD5E1"
                                            },
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                border: folderAddErr ? "1px solid #F87171" :"none",
                                                boxShadow: folderAddErr ? "none": '0 0 0 1px #4F46E5, 0 0 0 2px #C7D2FE'
                                            }
                                        },
                                        "& input:-webkit-autofill": {
                                            WebkitBoxShadow: "0 0 0px 1000px #FFFFFF inset"
                                        }
                                    }}
                                />
                                {
                                    folderAddErr && 
                                    <Typography style={webStyle.errorText}>
                                        {folderAddErr}
                                    </Typography>
                                }
                            </Box>
                        }
                        <Box
                            style={webStyle.modInnerLastBox}
                        >
                            <CancelButton
                                data-test-id="modalCancelButtonTestId"
                                style = {{width: "100px"}}
                                onClick={()=>this.handleAddFolderModalClose()}
                            >
                                Cancel
                            </CancelButton>
                            {
                                (modalOpen === "addFolder" || modalOpen === "renameFolder")?
                                <OkButton
                                    data-test-id = "modalChangeButtonTestId"
                                    style = {{width: "135px"}}
                                    onClick = {()=> this.handleModalOkClick()}
                                    disabled = {folderNameAdd.length === 0}
                                >
                                    {modalOpen === "addFolder"? "Add Folder": "Change"}
                                </OkButton>:
                                <DeleteButton
                                    data-test-id="modalDeleteButtonTestId"
                                    style = {{width: "135px"}}
                                    onClick = {()=> modalOpen === "deleteFile"?
                                        this.deleteFileApi(Number(this.state.selectedFileId)):
                                        this.deleteFolderApi(Number(this.state.selectedFolderId))
                                    }
                                >
                                    Delete
                                </DeleteButton>
                            } 
                        </Box>
                    </Box>
                </Box>
            </Modal>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { 
            insideFolder,
            selectedFolderName,
            currentPage,
            folderCurrentPage,
            totalFolderPages,
            foldersArray,
            loading,
            fileArray
        } = this.state;
        const folderData = {
            folderName: selectedFolderName,
            folderFiles: fileArray,
            currentPage: currentPage,
            totalPages: totalFolderPages
        };
        // Customizable Area End
        return (
            // Customizable Area Start
            <Drawer navigation={this.props.navigation} pageName = "Forms">
                <Box style={webStyle.mainBox}>
                    {
                        !insideFolder?
                        <>
                            <PageHeader
                                data-test-id="pageHeaderTestId"
                                heading={configJSON.Forms} 
                                handleAddFolder={()=>this.handleModalOpen("addFolder", 0)}
                            />
                            <Box style={webStyle.folderMainBox}>
                                <Typography style={webStyle.folderText}>
                                    {configJSON.Folders}
                                </Typography>
                                {foldersArray.length === 0?
                                    <Box
                                        style={webStyle.noFolderBox}
                                    >
                                        <Typography
                                            style={webStyle.noFolderText}
                                        >
                                            {loading? "Loading...": "No folder is available !"}
                                        </Typography>
                                    </Box>:
                                    <>
                                        <Grid
                                            container
                                            spacing={3}
                                            style={webStyle.folderContainerGrid}
                                        >
                                            {foldersArray.map((item, index) => 
                                                <Grid
                                                    data-test-id={`folderGrid${index}TestId`}
                                                    item 
                                                    sm={4}
                                                    md={3}
                                                    key={index}
                                                >
                                                    <Box
                                                        data-test-id={`folderBoxTestId${index}`}
                                                        onClick={() => this.handleFolderOpen(item.attributes.folder_name, Number(item.id))}
                                                    >   
                                                        <FolderView
                                                            data-test-id={`folderTestId${index}`}
                                                            id={Number(item.id)}
                                                            folderName={item.attributes.folder_name}
                                                            fileCount={item.attributes.total_files? `${item.attributes.total_files}`: "0"}
                                                            iconColor={colorArray[index]}
                                                            handleMenuClick = {this.handleFolderMenuClick}
                                                        />
                                                    </Box>
                                                </Grid>
                                            )}
                                        </Grid>
                                        <Box
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                width: "100%",
                                                height: "40px",
                                                marginTop: "30px"
                                            }}
                                        >
                                            <Typography style={webStyle.pageText}>
                                                Page { folderCurrentPage } of { totalFolderPages }
                                            </Typography>
                                            <StyledPagination
                                                data-test-id="folderPaginationTestId"
                                                page={folderCurrentPage}
                                                count={totalFolderPages}
                                                variant="outlined"
                                                shape="rounded"
                                                totalPages={folderData.totalPages}
                                                onChange={this.handleFolderPageChange}
                                            />
                                        </Box>
                                    </>
                                }
                            </Box>
                        </>:
                        <InsideFolder
                            data-test-id="insideFolderTestId"
                            loading = {loading}
                            folderData = {folderData}
                            backClick={this.handleFolderClose}
                            handleUploadButtonClick={this.handleUploadFileApi}
                            handlePageChange={(page)=> this.handleFilePageChange(page)}
                            handleMenuClick={this.handleFileMenuClick}
                        />
                    }
                </Box>
                {this.renderFolderNameInputModal()}
                {this.renderSuccessSnackbar()}
                {this.renderErrorSnackbar()}
                <style>
                    {
                        `.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
                            top:0px !important
                        }`
                    }
                </style>
            </Drawer>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const CancelButton = styled(Button)({
    background: "#EEF2FF",
    borderRadius: "100px",
    padding: "10px 16px",
    color: "#6366F1",
    fontWeight: 600,
    textTransform: "none",
    "&:hover": {
        background: "#EEF2FF",
        color: "#6366F1"
    }
});

const OkButton = styled(Button)({
    background: "#6366F1",
    borderRadius: "100px",
    padding: "10px 16px",
    color: "#FFFFFF",
    fontWeight: 600,
    textTransform: "none",
    "&:hover": {
        background: "#6366F1",
        color: "#FFFFFF"
    },
    "&:disabled": {
        background: "#6366F1", 
        color: "#FFFFFF",
        opacity: 0.6,
        pointerEvents: "none"
    }
});

const DeleteButton = styled(Button)({
    background: "#DC2626",
    borderRadius: "100px",
    padding: "10px 16px",
    color: "#FFFFFF",
    fontWeight: 600,
    textTransform: "none",
    "&:hover": {
        background: "#DC2626",
        color: "#FFFFFF"
    },
    "&:disabled": {
        background: "#DC2626", 
        color: "#FFFFFF",
        opacity: 0.6,
        pointerEvents: "none"
    }
});

const StyledPagination = styled(Pagination)<StyledPaginationProps>(({ totalPages }) => ({
    "& .MuiPaginationItem-root": {
        margin: "0",
        border: "1px solid #E5E5E5",
        borderRadius: "0px",
        height: "32px",
        boxShadow: "0px 4px 8px -5px #00000026",
        color: "#0F172A",
        borderRight: "1px solid #E2E8F0",
        borderLeft: "none",
        fontWeight: 600,
        fontSize: "12px",
        background: "#FFFFFF"
    },
    "& .MuiPaginationItem-root[aria-label='Go to next page']": {
        marginLeft: "10px",
        borderRadius: "8px",
        border: "1px solid #E5E5E5"
    },
    "& .MuiPaginationItem-root[aria-label='Go to previous page']": {
        marginRight: "10px",
        borderRadius: "8px",
        border: "1px solid #E5E5E5"
    },
    "& .MuiPaginationItem-root[aria-label$='page 1']": {
        borderRadius: "8px 0 0 8px",
        borderLeft: "1px solid #E5E5E5"
    },
    [`& .MuiPaginationItem-root[aria-label$='page ${totalPages}']`]: {
        borderRadius: "0 8px 8px 0",
        borderRight: "1px solid #E5E5E5"
    },
    "& .MuiPaginationItem-root:hover": {
        background: "#E2E8F0"
    },
    "& .MuiPaginationItem-root.Mui-selected": {
        background: "#E2E8F0"
    }
}));

const webStyle = {
    mainBox: {
        background: "#fafaf9",
        // background: "#c4c2c226",
        padding: "24px",
        height: "100%",
        minHeight: "400px"
    },
    pageText: {
        fontSize: "14px",
        color: "#0F172A",
        fontWeight: 500
    },
    modBoxStyle: {
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    modInnerBoxStyle: {
        background: "#FFFFFF",
        borderRadius: "16px",
        width: "480px"
    },
    modInnerTopBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "24px 24px 12px"
    },
    modInnerMidBox: {
        padding: "24px",
        borderTop: "1px solid #E2E8F0",
        borderBottom: "1px solid #E2E8F0"
    },
    modInnerLastBox: {
        padding: "24px",
        display: "flex",
        justifyContent: "flex-end",
        gap: "12px"
    },
    addFolderMod: {
        fontSize: "18px",
        color: "#0D0D12",
        fontWeight: 600
    },
    folderContainerGrid: {
        marginTop: "20px"
    },
    folderMainBox: {
        marginTop: "25px"
    },
    folderText: {
        color: "#0F172A",
        fontSize: "20px",
        fontWeight: 600
    },
    labelStyle: {
        fontSize: "14px",
        fontWeight: 600,
        color: "#475569",
        marginBottom: "3px"
    },
    errorText: {
        color: "#DC2626",
        fontSize: "12px",
        marginTop: "5px"
    },
    noFolderBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
        height: "300px"
    },
    noFolderText: {
        fontSize: "30px",
        color: "#94A3B8",
        fontWeight: 600
    }
};
// Customizable Area End
