// Customizable Area Start

import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { setStorageData, getStorageData, removeStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

interface LoginResponse {
  errors: {
    failed_login: string;
  }[];
  meta: {
    token: string;
  };
};

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  password: string;
  email: string;
  showPassword: boolean;
  canLogin: boolean,
  isChecked: boolean,
  errorLogin: boolean,
  errorMessage: string,
  loginClicked: boolean,
  emailError: string;
  passError: string;
}

interface SS {
  id: any;
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {

  timeoutId: number | null = null;

  apiEmailLoginCallId = '';

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      showPassword: false,
      canLogin: false,
      isChecked: false,
      errorLogin: false,
      errorMessage: '',
      loginClicked: false,
      emailError: "",
      passError: ""
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const getToken = await getStorageData("authToken");
    if(getToken) {
      this.redirectToDashboard();
    }
    this.checkDataFromLocalStorage();
  };

  checkDataFromLocalStorage = async () => {
    const userEmail = await getStorageData("UserEmail");
    const userPassword = await getStorageData("UserPassword");
    if (userEmail) {
      const sellerEmail = atob(userEmail);
      this.setState({
        email: sellerEmail
      })
    }
    if (userPassword) {
      const sellerPassword = atob(userPassword);
      this.setState({
        password: sellerPassword
      })
    }
    if (userEmail && userPassword) {
      this.setState({
        isChecked: true
      })
    }
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      { 
        email: event.target.value
      }, () => 
      this.validationInputField()
    );
  };

  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.length <= 128) {
      this.setState(
        { 
          password: value
        }, () => 
        this.validationInputField()
      );
    }
  };

  validationInputField = () => {
    let emailError = "", passError = "";
    const { email, password } = this.state;
    if(!configJSON.emailRegex.test(email)) {
      emailError = "Incorrect e-mail"
    }
    if (password.length < 1) {
      passError = "Password is required";
    }
    this.setState({
      emailError: emailError,
      passError: passError
    });
    return (emailError.length === 0 && passError.length === 0);
  };

  handleCloseError = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ errorLogin: false });
  };

  redirectToDashboard = () => {
    this.props.navigation.navigate("Dashboard")
  }

  redirectToForgotPassword =()=>{
    this.props.navigation.navigate("ForgotPasswordWeb")
  }

  toggleCheckbox = () => {
    this.setState({ isChecked: !this.state.isChecked })
  }

  handleLogin = async () => {
    const validField = this.validationInputField();
    const encodeUserName = btoa(this.state.email);
    const encodePassword = btoa(this.state.password);
    this.setState({
      loginClicked: true
    });
    if(validField === true) {
        if (this.state.isChecked) {
          await setStorageData("UserEmail", encodeUserName);
          await setStorageData("UserPassword", encodePassword);
        } else {
          await removeStorageData("UserEmail");
          await removeStorageData("UserPassword");
        }
      this.emailAccountLogin();
    }
  };

  emailAccountLogin = () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.APILoginEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleLoginApiResponse = async (apiRequestCallId: string, responseJson: LoginResponse) => {
    if (apiRequestCallId === this.apiEmailLoginCallId && responseJson) {
      if ( responseJson.errors && responseJson.errors[0]) {
        if(responseJson.errors[0].failed_login === 'Account not found, or not activated') {
          this.setState({ 
            errorLogin: true,
            errorMessage: 'Account not found, or not activated'
          }, () => this.handleSnackbarOpenClick());
        } else if (responseJson.errors[0].failed_login === 'Login Failed') {
          this.setState({ 
            errorLogin: true,
            errorMessage: `Email and password don't match`
          }, () => this.handleSnackbarOpenClick());
        }
      } else if(responseJson.meta) {
        await setStorageData("authToken", responseJson.meta.token);
        this.redirectToDashboard();
      }
    }
  };

  handleSnackbarOpenClick = () => {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = window.setTimeout(() => {
      this.setState({
        errorLogin: false,
        errorMessage: ``,
      });
    }, 4000);
  };

  handleSnackbarClose = () => {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
    }
    this.setState({
      errorLogin: false,
      errorMessage: ``
    });
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleLoginApiResponse(apiRequestCallId, responseJson)
    }
  }

}

// Customizable Area End