// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

// Customizable Area Start
interface S {
  showPassword: boolean;
  email: string;
  checkSubmit: boolean;
  showConfirmPassword: boolean;
  isSentEmail: boolean;
  formError: string;
  newPassword: string;
  isResetPwd: boolean;
  isPasswordResetError: string;
  confirmNewPassword: string;
  confirmNewPasswordError: string,
  newPasswordError: string,
  toastType: "error" | "success",
  token:string,
  errorState: boolean,
}
// Customizable Area End

interface SS {
  id: any;
}

export default class NewPasswordController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  resetPasswordText: string = configJSON.resetPasswordText
  emailLabelText: string = configJSON.firstInputPlaceholder;
  resetPwdInstrucText: string = configJSON.resetPasswordInstructionText;
  emailInvalidText: string = configJSON.invalidEmailAddress;
  resetPwdSecText: string = configJSON.resetPasswrodSecondaryText;
  buttonSendText: string = configJSON.sendButtonText;
  sentMailToText: string = configJSON.sentMailToText;
  enterEmail: string = configJSON.enterEmalplaceholderText;
  createPwdText: string = configJSON.createPwdText;
  createNewPwd: string = configJSON.createNewPwd;
  enterNewPassword: string = configJSON.enterNewPassword;
  newPasswordLabelText: string = configJSON.newPasswordLabelText;
  confirmNewPasswordLabelText: string = configJSON.confirmNewPasswordLabelText;
  enterConfirmNewPassword: string = configJSON.enterConfirmNewPassword;
  backToLogin: string = configJSON.backToLogin;
  resetPasswordButton: string = configJSON.resetPasswordButton;
  apiResetPassowrdCallId = '';
  successPwdText: string = configJSON.successPwdText;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      showPassword: false,
      checkSubmit: false,
      formError: "",
      showConfirmPassword: false,
      isResetPwd: false,
      newPassword: '',
      isSentEmail: false,
      confirmNewPassword: '',
      isPasswordResetError: '',
      confirmNewPasswordError: "",
      newPasswordError: "",
      toastType: 'success',
      token:"",
      errorState: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    super.componentDidMount()
    const getAuthToken = await getStorageData("authToken");
    if(getAuthToken) {
      this.handleNavigationRoute("Dashboard");
    }
    let url = window.location;
    let tempToken = new URLSearchParams(url.search).get("token")

    if(tempToken){
      this.setState({token:tempToken})
    }else{
      this.getToken();
    }
  }

  getToken=()=>{
    const message:Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value, formError: "" });
  };

  validateEmail = (email: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return configJSON.emailRegex.test(email);
  };

  validateNewPassword = () => {
    const { newPassword } = this.state;
  
    const isEmpty = (password: string) => !password;
    const isTooShort = (password:any) => password.length < 8;
    const hasUppercase = (password: string) => /[A-Z]/.test(password);
    const hasLowercase = (password: string) => /[a-z]/.test(password);
    const hasNumber = (password: string) => /\d/.test(password);
    const hasSpecialChar = (password: string) => /[^A-Za-z0-9]/.test(password);
  
    let error = "";
  
    if (isEmpty(newPassword)) {
      error = "New password is required.";
    } else if (isTooShort(newPassword)) {
      error = "Password must be at least 8 characters.";
    } else if (!hasUppercase(newPassword)) {
      error = "Password must contain at least one uppercase letter.";
    } else if (!hasLowercase(newPassword)) {
      error = "Password must contain at least one lowercase letter.";
    } else if (!hasNumber(newPassword)) {
      error = "Password must contain at least one number.";
    } else if (!hasSpecialChar(newPassword)) {
      error = "Password must contain at least one special character.";
    }
  
    this.setState({ newPasswordError: error });
    return !error;
  };
  


  validateConfirmPassword = () => {
    const { newPassword, confirmNewPassword } = this.state;
    let error = "";

    if (!confirmNewPassword) {
      error = "Confirm password is required.";
    } else if (newPassword !== confirmNewPassword) {
      error = "Passwords do not match.";
    }

    this.setState({ confirmNewPasswordError: error });
    return !error;
  };

  handleSubmit = () => {
    this.setState({
      checkSubmit: true
    });
    const isNewPasswordValid = this.validateNewPassword();
    const isConfirmPasswordValid = this.validateConfirmPassword();
    if (isNewPasswordValid && isConfirmPasswordValid) {
      this.resetPasswordSentLinkToEmail()
    }
  };
  


  resetPasswordSentLinkToEmail = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const token_ = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NiwiZXhwIjoxNzMzOTQ0MDI0LCJ0b2tlbl90eXBlIjoibG9naW4ifQ.mMO-QONjNt1Sf94qJGjmrMM8cc9Qxb9pMULdgwGegQr-LN1rj03va3NDd9uLL0dlNY16pXwJkNRZjbUrh2EKBg"

    const data = {
      token: token_,
      new_password: this.state.newPassword,
      confirm_password: this.state.confirmNewPassword,
    }

    const httpBodyData = {
      data: data,
    };

    const requestMessage_ = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetPassowrdCallId = requestMessage_.messageId;

    requestMessage_.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_forgot_password/passwords"
    );

    requestMessage_.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage_.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBodyData)
    );

    requestMessage_.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage_.id, requestMessage_);
  }

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      if(token) {
        this.setState({token:token})
      } else {
        let tokenStorage = await getStorageData("authToken")
        this.setState({token:tokenStorage})
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.apiResetPassowrdCallId !==null && this.apiResetPassowrdCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleResetPasswordApiResponse(apiRequestCallId, responseJson)
    }
  }

  handleResetPasswordApiResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.apiResetPassowrdCallId){
  
    const errors = responseJson && responseJson.errors && responseJson.errors[0];
    const message = responseJson && responseJson.message;
  
    if (errors && errors.profile === "Password change failed") {
      this.setState({
        errorState: true,
        toastType: "error",
        formError: errors.profile,
      });
    }else if(errors&& errors.token==="Invalid token"){
      this.setState({
        errorState: true,
        toastType: "error",
        formError: errors.token,
      });
    }else if (responseJson !== undefined && message) {
      const msg:Message = new Message(getName(MessageEnum.NavigationEmailLogInMessage));
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg)
      this.setState({
        isResetPwd: true,
        toastType: "success",
        formError: message,
      });
    }
  }
  };
  
  redirectToLogin = () => {
    this.props.navigation.navigate("Home");
  };

  redirectToForgotPassword = () => {
    this.props.navigation.navigate("ForgotPassword");
  };

  togglePasswordVisibility = (label: string) => {
    if(label === "New password")
      this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
    else 
    this.setState((prevState) => ({ showConfirmPassword: !prevState.showConfirmPassword }));
  }

  handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({ newPassword: value }, ()=> {
      this.validateNewPassword();
      this.validateConfirmPassword();
    });
  };

  handleNavigationRoute = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleConfirmNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({ confirmNewPassword: value }, ()=> {
      this.validateNewPassword();
      this.validateConfirmPassword();
    });
  };

  handleCloseError = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ errorState: false });
  };

  // Customizable Area End
}
