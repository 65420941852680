import React from "react";
// Customizable Area Start
import { 
  Box,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  styled, 
  Grid,
  IconButton
} from "@mui/material";
import { noteUncheckBox } from './assets'
import Drawer from "./Drawer.web";
import EditIcon from '@mui/icons-material/Edit';
import NoteEditor from "../../../components/src/NoteEditor";

// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderClientNotifications = (heading: string) => {
    const { notificationsData } = this.state;
    return(
      <>
        <Box style={webStyles.notHeadingWrapper}>
          <Box style={webStyles.notHeadingBox}>
            <Typography style={webStyles.notHeadingText}>{heading}</Typography>
            { heading === "Client Notifications" &&
              <Box style={webStyles.notCountBox}>
                <Typography>
                  2
                </Typography>
              </Box>
            }
          </Box>
          { heading === "Notes" && 
            <IconButton
              data-test-id={"addNoteIconButtonTestId"}
              onClick={this.handleNotes}
            >
              <EditIcon style={{color: "#0A0A0A"}}/>
            </IconButton>
          }
        </Box>
        { heading === "Notes"? 
          <Box style={webStyles.notesBodyBox}>
            <Box style={webStyles.noteItemWrapper}>
              <Typography style={webStyles.noteItemHeadingText}>
                {webConfigJSON.noteItemHeading}
              </Typography>
              <Typography style={webStyles.noteItemDetailText}>
                {webConfigJSON.noteItemDetail}
              </Typography>
              <Box style={webStyles.noteListItemWrapper}>
                {[
                  "Automobile",
                  "Real Estate Assets",
                  "Tech"
                ].map((item, index)=> 
                  <Box
                    key={index}
                    style={webStyles.noteCheckItemBox}
                  >
                    {noteUncheckBox}
                    <Typography style={webStyles.noteItemDetailText}>
                      {item}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Typography style={webStyles.noteItemDetailText}>
                ...
              </Typography>
            </Box>
          </Box>:
          <Box style={webStyles.notiItemsmainWrapper}>
            <Box
                style={webStyles.notiItemsBorderWrapper}
              ></Box>
            <Box style={webStyles.notiItemsWrapper}>
              <Box
                style={webStyles.notiItemsContainer}
              >
              {
                notificationsData.map((item, index)=> 
                  <NotItemBox
                    key={index}
                  >
                    <Box style={webStyles.notiItemBox}>
                      {item.icon}
                    </Box>
                    <Box>
                      <Box style={webStyles.notItemHeadingBox}>
                        <Typography
                          id="notItemHeadingText"
                          style={{
                            ...webStyles.notItemHeadingText,
                            fontWeight: item.isUnread? 600: 400
                          }}
                        >
                          {item.title}
                        </Typography>
                        {
                          item.isUnread &&
                          <span style={webStyles.readMark}>
                          </span>
                        }
                      </Box>
                      <Typography
                        id="notItemBodyText"
                        style={webStyles.notItemBodyText}>
                        {item.message}
                      </Typography>
                      <Typography
                        id="notItemTimeText"
                        style={
                          item.isUnread?
                          webStyles.notItemTimeText:
                          webStyles.notItemReadTimeText
                        }
                      >
                        {item.timeAgo}
                      </Typography>
                    </Box>
                  </NotItemBox>
                )
              }
              </Box>
            </Box>
          </Box>
        }
      </>
    )
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { tableData, loading, isOpen, value } = this.state;
    return (
      //Merge Engine DefaultContainer
      <Drawer navigation={this.props.navigation} pageName = "Dashboard">
        <Box style={webStyles.mainBox}>
          <Box style={webStyles.tableWrapper}>
            <Typography style={webStyles.clientUptatesHeading}>{webConfigJSON.clientUpdates}</Typography>
            <StyledTableContainer>
              <Table>
                <StyledTableHead>
                  <TableRow>
                    <TableCell>
                      <Box style={webStyles.nameHeadingCell}>
                        {
                          <Box style={webStyles.checkBoxNotSelect}>
                          </Box>
                        }
                        {webConfigJSON.client}
                      </Box>
                    </TableCell>
                    <TableCell>{webConfigJSON.detail}</TableCell>
                    <TableCell>{webConfigJSON.updated}</TableCell>                  
                    <TableCell></TableCell>
                  </TableRow>
                </StyledTableHead>
                {
                  (tableData.length === 0)?
                  <StyledTableBody>
                    <TableCell colSpan={4}>
                    <Box style={webStyles.noFileBox}>
                      <Typography style={webStyles.noFileText}>
                        {loading? "Loading...": "No file is available !"}
                      </Typography>
                    </Box>
                    </TableCell>
                  </StyledTableBody>:
                  <StyledTableBody>
                    {tableData.map((item, index) => 
                      <TableRow key={index}>
                        <TableCell>
                          <Box style={webStyles.cellCheckTextBox}>
                            <Box style={webStyles.checkBoxNotSelect}>
                            </Box>
                            <Typography style={webStyles.cellText}>
                              {item.name}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell style={webStyles.cellText}>
                          {item.detail}
                        </TableCell>
                        <TableCell style={webStyles.cellText}>
                          {item.updated}
                        </TableCell>
                        <TableCell align="center" style={webStyles.cellViewText}>
                          <Typography style={webStyles.viewText}>
                            {webConfigJSON.view}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </StyledTableBody>
                }
              </Table>
            </StyledTableContainer>
          </Box>
          <Box mt={2}>
            <Grid container spacing={3}>
              {[
                this.renderClientNotifications("Client Notifications"),
                this.renderClientNotifications("Notifications"),
                this.renderClientNotifications("Notes")
              ].map((item, index) =>
                <Grid 
                  key={index}
                  item
                  sm={4}
                  md={4}
                >
                  <Box
                    style={webStyles.gridItemBoxStyle}
                  >
                    {item}
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
        <Dialog 
          data-test-id="dialog"
          PaperProps={{
            style: {
              borderRadius: "16px",
              width: "700px",
              maxWidth: "700px",
              minHeight: "fit-content"
            }
          }}
          open={isOpen}
          onClose={this.handleClose}
        >
          <NoteEditor
            data-test-id="noteEditorTestId"
            value={value}
            handleAddNote={this.handleAddNote}
            handleClose={this.handleClose}
          />
        </Dialog>
      </Drawer>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const NotItemBox = styled(Box)({
  padding: "8px",
  display: "flex",
  gap: "15px",
  border: "1px solid transparent",
  "&:hover": {
    border: "1px solid #E5E5E5",
    borderRadius: "8px",
    background: "#FAFAFA",
    "& #notItemHeadingText": {
      fontWeight: "normal !important"
    },
    "& #notItemBodyText": {
      color: "#0A0A0A !important"
    },
    "& #notItemTimeText": {
      color: "#94A3B8 !important",
      fontWeight: "normal !important"
    }
  }
});

const StyledTableContainer = styled(TableContainer)({
  border: "1px solid #E2E8F0",
  borderRadius: "12px",
  overflow: "hidden",
  background: "white",
  maxHeight: "322px",
  overflowY: "scroll"
});

const StyledTableHead = styled(TableHead)({
  "& .MuiTableRow-root": {
    backgroundColor: "#F8FAFC"
  },
  "& .MuiTableCell-root": {
    borderRight: "1px solid #E2E8F0",
    borderBottom: "1px solid #E2E8F0",
    color: "#334155",
    fontSize: "14px",
    fontWeight: 500,
    padding: "8px 16px"
  },
  "& .MuiTableCell-root:last-child": {
    borderRight: "none"
  }
});

const StyledTableBody = styled(TableBody)({
  "& .MuiTableCell-root": {
    borderRight: "1px solid #E2E8F0",
    borderBottom: "1px solid #E2E8F0",
    color: "#0F172A",
    fontSize: "14px",
    fontWeight: 500,
    padding: "12px 16px"
  },
  "& .MuiTableCell-root:last-child": {
    borderRight: "none"
  }
});

const webStyles = {
  mainBox: {
    background: "#fafaf9",
    padding: "24px",
    height: "100%",
    minHeight: "400px"
  },
  nameHeadingCell: {
    display: "flex",
    gap: "15px"
  },
  notesBodyBox: {
    background: "#FEF3C7",
    borderRadius: "10px",
    padding: "18px",
    height: "356px",
    overflowY: "scroll"
  } as React.CSSProperties,
  noteItemWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "10px"
  } as React.CSSProperties,
  notItemHeadingBox: {
    display:"flex",
    position: "relative"
  } as React.CSSProperties,
  readMark: {
    position: "absolute",
    width: "6px",
    height: "6px",
    borderRadius: "100px",
    background: "#F87171",
    right: "0px",
    top: "5px"
  } as React.CSSProperties,
  notItemHeadingText: {
    color: "#0A0A0A",
    fontSize: "14px",
    fontWeight: 600,
    marginRight: "5px"
  },
  notItemBodyText: {
    color: "#64748B",
    fontSize: "12px"
  },
  noteListItemWrapper: {
    display: "flex",
    gap: "10px",
    flexDirection: "column"
  } as React.CSSProperties,
  noteCheckItemBox: {
    display: "flex",
    gap: "15px"
  },
  notItemTimeText: {
    color: "#475569",
    fontSize: "12px",
    fontWeight: 600
  },
  notItemReadTimeText: {
    color: "#94A3B8",
    fontSize: "12px",
    fontWeight: 400
  },
  notHeadingWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px"
  },
  notiItemBox: {
    borderRadius: "100px",
    width: "32px",
    minWidth: "32px",
    height: "32px",
    color: "#FFFFFF",
    fontSize: "12px",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  noteItemHeadingText: {
    color: "#0F172A",
    fontSize: "14px",
    fontWeight: 600
  },
  noteItemDetailText: {
    color: "#334155",
    fontSize: "14px",
  },
  notHeadingBox: {
    display: "flex",
    gap: "15px",
    alignItems: "center"
  },
  notHeadingText: {
    fontSize: "20px",
    fontWeight: 600,
    color: "##0F172A"
  },
  notCountBox: {
    borderRadius: "100px",
    background: "#F87171",
    width: "24px",
    height: "24px",
    color: "#FFFFFF",
    fontSize: "12px",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  gridItemBoxStyle: {
    border: "1px solid #E2E8F0",
    borderRadius: "16px",
    padding: "18px",
    background: "#FFFFFF"
  },
  notiItemsmainWrapper: {
    position: 'relative',
    zIndex: 0
  } as React.CSSProperties,
  notiItemsWrapper: {
    padding: "10px",
    height: "380px",
    overflowY: "scroll",
    zIndex: 5
  } as React.CSSProperties,
  notiItemsBorderWrapper: {
    border: "1px solid #E2E8F0",
    borderRadius: "10px",
    height: "400px",
    width: "93%",
    top: "0px",
    position: 'absolute',
    zIndex: -1
  } as React.CSSProperties,
  notiItemsContainer: {
    width: "93%"
  },
  cellCheckTextBox: {
    display: "flex",
    gap: "15px"
  },
  noFileBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "400px"
  },
  noFileText: {
    fontSize: "30px",
    color: "#94A3B8",
    fontWeight: 600
  },
  cellText: {
    fontSize: "14px",
    color: "#0F172A"
  },
  cellViewText: {
    fontWeight: 600,
    color: "#6366F1",
    width: "56px"
  },
  viewText: {
    fontWeight: 600,
    fontSize: "14px",
    cursor: "pointer"
  },
  checkBoxNotSelect: {
    border: "1px solid #CBD5E1",
    background: "#FFFFFF",
    borderRadius: "6px",
    width: "20px",
    height: "20px",
    overflow: "hidden",
    cursor: "pointer"
  },
  tableWrapper: {
    background: "#ffffff",
    padding: "18px",
    borderRadius: "16px"
  },
  clientUptatesHeading: {
    fontSize: "20px",
    fontWeight: 600,
    color: "##0F172A",
    marginBottom: "10px"
  }
};
// Customizable Area End
