import React from "react"
export const logo = require("../assets/logo.png")
export const check = require("../assets/check.png")
export const uncheck = require("../assets/uncheck.png")
export const lock = require('../assets/lock.png')
export const search = require('../assets/search.png')
export const userImage = require('../assets/userImage.png');
export const msgIcon = require('../assets/msgIcon.svg').default;
export const homeIcon = require('../assets/homeIcon.svg').default;
export const clientImage = require("../assets/clientImage.jpeg");
export const Wealthfusion_logo = require("../assets/wealthfusionLogo.png");

export const home = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.99992 15.8334H7.49992V10.8334H12.4999V15.8334H14.9999V8.33341L9.99992 4.58341L4.99992 8.33341V15.8334ZM4.99992 17.5001C4.54159 17.5001 4.14936 17.337 3.82325 17.0109C3.49659 16.6842 3.33325 16.2917 3.33325 15.8334V8.33341C3.33325 8.06953 3.39242 7.81953 3.51075 7.58342C3.62853 7.3473 3.79159 7.15286 3.99992 7.00008L8.99992 3.25008C9.1527 3.13897 9.31242 3.05564 9.47908 3.00008C9.64575 2.94453 9.81936 2.91675 9.99992 2.91675C10.1805 2.91675 10.3541 2.94453 10.5208 3.00008C10.6874 3.05564 10.8471 3.13897 10.9999 3.25008L15.9999 7.00008C16.2083 7.15286 16.3716 7.3473 16.4899 7.58342C16.6077 7.81953 16.6666 8.06953 16.6666 8.33341V15.8334C16.6666 16.2917 16.5035 16.6842 16.1774 17.0109C15.8508 17.337 15.4583 17.5001 14.9999 17.5001H10.8333V12.5001H9.16658V17.5001H4.99992Z"/>
</svg>

export const file = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.4999 1.66675H4.99992C4.55789 1.66675 4.13397 1.84234 3.82141 2.1549C3.50885 2.46746 3.33325 2.89139 3.33325 3.33341V16.6667C3.33325 17.1088 3.50885 17.5327 3.82141 17.8453C4.13397 18.1578 4.55789 18.3334 4.99992 18.3334H14.9999C15.4419 18.3334 15.8659 18.1578 16.1784 17.8453C16.491 17.5327 16.6666 17.1088 16.6666 16.6667V5.83341L12.4999 1.66675Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="transparent"/>
<path d="M11.6667 1.66675V5.00008C11.6667 5.44211 11.8423 5.86603 12.1549 6.17859C12.4675 6.49115 12.8914 6.66675 13.3334 6.66675H16.6667" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="transparent"/>
<path d="M8.33341 7.5H6.66675" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.3334 10.8333H6.66675" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.3334 14.1667H6.66675" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


export const files = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.6667 5.83341H14.1667C13.7246 5.83341 13.3007 5.65782 12.9882 5.34526C12.6756 5.0327 12.5 4.60878 12.5 4.16675V1.66675" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="transparent"/>
<path d="M7.49992 15.0001C7.05789 15.0001 6.63397 14.8245 6.32141 14.5119C6.00885 14.1994 5.83325 13.7754 5.83325 13.3334V3.33341C5.83325 2.89139 6.00885 2.46746 6.32141 2.1549C6.63397 1.84234 7.05789 1.66675 7.49992 1.66675H13.3333L16.6666 5.00008V13.3334C16.6666 13.7754 16.491 14.1994 16.1784 14.5119C15.8659 14.8245 15.4419 15.0001 14.9999 15.0001H7.49992Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="transparent"/>
<path d="M2.5 6.33325V16.9999C2.5 17.3535 2.64048 17.6927 2.89052 17.9427C3.14057 18.1928 3.47971 18.3333 3.83333 18.3333H12" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="transparent"/>
</svg>

export const pdf = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.4999 1.66675H4.99992C4.55789 1.66675 4.13397 1.84234 3.82141 2.1549C3.50885 2.46746 3.33325 2.89139 3.33325 3.33341V16.6667C3.33325 17.1088 3.50885 17.5327 3.82141 17.8453C4.13397 18.1578 4.55789 18.3334 4.99992 18.3334H14.9999C15.4419 18.3334 15.8659 18.1578 16.1784 17.8453C16.491 17.5327 16.6666 17.1088 16.6666 16.6667V5.83341L12.4999 1.66675Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="transparent"/>
<path d="M11.6667 1.66675V5.00008C11.6667 5.44211 11.8423 5.86603 12.1549 6.17859C12.4675 6.49115 12.8914 6.66675 13.3334 6.66675H16.6667" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="transparent"/>
<path d="M7.5 12.4999L9.16667 14.1666L12.5 10.8333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="transparent"/>
</svg>

export const chat = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4 2.5H16C16.825 2.5 17.5 3.175 17.5 4V13C17.5 13.825 16.825 14.5 16 14.5H5.5L2.5 17.5V4C2.5 3.175 3.175 2.5 4 2.5ZM5.5 13H15.25C15.6625 13 16 12.6625 16 12.25V4.75C16 4.3375 15.6625 4 15.25 4H4.75C4.3375 4 4 4.3375 4 4.75V14.5L5.5 13Z"/>
</svg>;

export const people = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.538 11.0856C14.5758 11.7903 15.3031 12.7452 15.3031 14.0183V16.2917H18.3334V14.0183C18.3334 12.3663 15.6288 11.3887 13.538 11.0856ZM12.2728 10.2292C13.9471 10.2292 15.3031 8.87276 15.3031 7.198C15.3031 5.52323 13.9471 4.16675 12.2728 4.16675C11.9167 4.16675 11.5834 4.24253 11.2652 4.34862C11.894 5.12917 12.2728 6.12191 12.2728 7.198C12.2728 8.27409 11.894 9.26682 11.2652 10.0474C11.5834 10.1535 11.9167 10.2292 12.2728 10.2292ZM7.72736 10.2292C9.4016 10.2292 10.7577 8.87276 10.7577 7.198C10.7577 5.52323 9.4016 4.16675 7.72736 4.16675C6.05311 4.16675 4.69705 5.52323 4.69705 7.198C4.69705 8.87276 6.05311 10.2292 7.72736 10.2292ZM7.72736 5.68237C8.56069 5.68237 9.24251 6.36441 9.24251 7.198C9.24251 8.03159 8.56069 8.71362 7.72736 8.71362C6.89402 8.71362 6.21221 8.03159 6.21221 7.198C6.21221 6.36441 6.89402 5.68237 7.72736 5.68237ZM7.72736 10.9871C5.70462 10.9871 1.66675 12.0025 1.66675 14.0183V16.2917H13.788V14.0183C13.788 12.0025 9.75008 10.9871 7.72736 10.9871ZM12.2728 14.7761H3.1819V14.0259C3.33341 13.4802 5.6819 12.5027 7.72736 12.5027C9.77281 12.5027 12.1213 13.4802 12.2728 14.0183V14.7761Z"/>
</svg>

export const star = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.3404 7.39549L16.3737 7.74549C17.1071 7.80382 17.3987 8.72049 16.8404 9.20382L13.7821 11.8538L14.6987 15.7955C14.8654 16.5122 14.0904 17.0788 13.4571 16.6955L9.99872 14.6038L6.54038 16.6872C5.90705 17.0705 5.13205 16.5038 5.29872 15.7872L6.21538 11.8538L3.15705 9.20382C2.59872 8.72049 2.89872 7.80382 3.63205 7.74549L7.65705 7.40382L9.23205 3.68716C9.51538 3.01216 10.4821 3.01216 10.7654 3.68716L12.3404 7.39549ZM6.86538 14.9372L9.99872 13.0455L13.1404 14.9455L12.3071 11.3788L15.0737 8.97883L11.4237 8.66216L9.99872 5.29549L8.58205 8.65383L4.93205 8.97049L7.69872 11.3705L6.86538 14.9372Z"/>
</svg>

export const mail = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#FBBF24"/>
<rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#FBBF24"/>
<path d="M20.6667 11.3333H11.3333C10.597 11.3333 10 11.9302 10 12.6666V19.3333C10 20.0696 10.597 20.6666 11.3333 20.6666H20.6667C21.403 20.6666 22 20.0696 22 19.3333V12.6666C22 11.9302 21.403 11.3333 20.6667 11.3333Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 12.6665L16 16.6665L22 12.6665" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

export const requiestIcon = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="16" fill="#EC4899"/>
<path d="M10.7907 19.8429L14.324 16.3096L16.4907 18.4763C16.764 18.7496 17.204 18.7363 17.4574 18.4496L22.2374 13.0696C22.4707 12.8096 22.4574 12.4096 22.2107 12.1563C21.944 11.8896 21.4974 11.8963 21.244 12.1829L16.984 16.9696L14.7907 14.7763C14.6661 14.6514 14.497 14.5813 14.3207 14.5813C14.1444 14.5813 13.9753 14.6514 13.8507 14.7763L9.7907 18.8429C9.5307 19.1029 9.5307 19.5229 9.7907 19.7829L9.8507 19.8429C10.1107 20.1029 10.5374 20.1029 10.7907 19.8429Z" fill="white"/>
</svg>

export const transCompleted = <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.333252" width="32" height="32" rx="16" fill="#10B981"/>
<g clip-path="url(#clip0_22508_5982)">
<path d="M22.9998 14.6666H9.6665M15.6665 17.3333H12.3332M9.6665 13.4666L9.6665 18.5333C9.6665 19.28 9.6665 19.6534 9.81183 19.9386C9.93966 20.1895 10.1436 20.3934 10.3945 20.5213C10.6797 20.6666 11.0531 20.6666 11.7998 20.6666L20.8665 20.6666C21.6132 20.6666 21.9866 20.6666 22.2718 20.5213C22.5227 20.3934 22.7267 20.1895 22.8545 19.9386C22.9998 19.6534 22.9998 19.28 22.9998 18.5333V13.4666C22.9998 12.7198 22.9998 12.3465 22.8545 12.0613C22.7267 11.8104 22.5227 11.6064 22.2718 11.4786C21.9866 11.3333 21.6132 11.3333 20.8665 11.3333L11.7998 11.3333C11.0531 11.3333 10.6797 11.3333 10.3945 11.4786C10.1436 11.6064 9.93966 11.8104 9.81183 12.0613C9.6665 12.3465 9.6665 12.7198 9.6665 13.4666Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_22508_5982">
<rect width="16" height="16" fill="white" transform="translate(8.33325 8)"/>
</clipPath>
</defs>
</svg>

export const card = <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_21864_7095)">
<rect x="4" y="3" width="32" height="32" rx="16" fill="#6366F1"/>
<path d="M26.6666 17.6666H13.3333M19.3333 20.3333H15.9999M13.3333 16.4666L13.3333 21.5333C13.3333 22.28 13.3333 22.6534 13.4786 22.9386C13.6064 23.1895 13.8104 23.3934 14.0613 23.5213C14.3465 23.6666 14.7198 23.6666 15.4666 23.6666L24.5333 23.6666C25.28 23.6666 25.6534 23.6666 25.9386 23.5213C26.1895 23.3934 26.3934 23.1895 26.5213 22.9386C26.6666 22.6534 26.6666 22.28 26.6666 21.5333V16.4666C26.6666 15.7198 26.6666 15.3465 26.5213 15.0613C26.3934 14.8104 26.1895 14.6064 25.9386 14.4786C25.6534 14.3333 25.28 14.3333 24.5333 14.3333L15.4666 14.3333C14.7198 14.3333 14.3465 14.3333 14.0613 14.4786C13.8104 14.6064 13.6064 14.8104 13.4786 15.0613C13.3333 15.3465 13.3333 15.7198 13.3333 16.4666Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<filter id="filter0_d_21864_7095" x="0.5" y="0.5" width="39" height="39" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_21864_7095"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0509804 0 0 0 0 0.0509804 0 0 0 0 0.0705882 0 0 0 0.08 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_21864_7095"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_21864_7095" result="shape"/>
</filter>
</defs>
</svg>

export const clientRequiestIcon = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#3B82F6"/>
<rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#3B82F6"/>
<path d="M18.6667 10.6666C19.2867 10.6666 19.5967 10.6666 19.8511 10.7347C20.5412 10.9197 21.0803 11.4588 21.2653 12.1489C21.3334 12.4033 21.3334 12.7133 21.3334 13.3333V19.4666C21.3334 20.5867 21.3334 21.1467 21.1154 21.5746C20.9237 21.9509 20.6177 22.2569 20.2414 22.4486C19.8136 22.6666 19.2535 22.6666 18.1334 22.6666H13.8667C12.7466 22.6666 12.1866 22.6666 11.7588 22.4486C11.3824 22.2569 11.0765 21.9509 10.8847 21.5746C10.6667 21.1467 10.6667 20.5867 10.6667 19.4666V13.3333C10.6667 12.7133 10.6667 12.4033 10.7349 12.1489C10.9198 11.4588 11.4589 10.9197 12.1491 10.7347C12.4034 10.6666 12.7134 10.6666 13.3334 10.6666M14.4001 11.9999H17.6001C17.9734 11.9999 18.1601 11.9999 18.3027 11.9273C18.4282 11.8633 18.5302 11.7614 18.5941 11.6359C18.6667 11.4933 18.6667 11.3066 18.6667 10.9333V10.3999C18.6667 10.0266 18.6667 9.83987 18.5941 9.69726C18.5302 9.57182 18.4282 9.46983 18.3027 9.40591C18.1601 9.33325 17.9734 9.33325 17.6001 9.33325H14.4001C14.0267 9.33325 13.84 9.33325 13.6974 9.40591C13.572 9.46983 13.47 9.57182 13.4061 9.69726C13.3334 9.83987 13.3334 10.0266 13.3334 10.3999V10.9333C13.3334 11.3066 13.3334 11.4933 13.4061 11.6359C13.47 11.7614 13.572 11.8633 13.6974 11.9273C13.84 11.9999 14.0267 11.9999 14.4001 11.9999Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

export const noteUncheckBox = <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="1.1665" y="0.5" width="19" height="19" rx="5.5" fill="white"/>
<rect x="1.1665" y="0.5" width="19" height="19" rx="5.5" stroke="#FBBF24"/>
</svg>

export const button = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 6L8 10L12 6" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
